import { createSlice } from '@reduxjs/toolkit';

const subspecialtiesSlice = createSlice({
  name: 'subspecialties',
  initialState: {subspecialties: null},
  reducers: {
    setSubspecialties(state, action) {
      state.subspecialties =  action.payload;
    },
    clearState(state, action) {
      if (action === 'CLEAR_SESSION') {
        state = undefined;
      }
    },
  },
});

export const subspecialtiesActions = subspecialtiesSlice.actions; 

export default subspecialtiesSlice.reducer;