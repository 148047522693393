import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../UI/Loading';
import Card from 'react-bootstrap/Card';
import UpdateProgramContact from '../Modal/UpdateProgramContact';
import { getAPAJournalMember, updateIndividualAccount } from '../../lib/api';

const APAJournalMember = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [APAJournalMember, setAPAJournalMember] = useState();
    const programAccountId = useSelector(state => state.client.clientAccountId);

    useEffect(() => {
        if (!programAccountId) {
            return
        }
        const fetchProgramInfo = async () => {
            await getAPAJournalMember(programAccountId).then((memberData) => {
                setAPAJournalMember(memberData);    
                setIsLoading(false);           
            });
            
        }
        fetchProgramInfo();
    }, [programAccountId]);

    const updateRecord = async (data) => {
        setIsLoading(true);

        if (!data) {
            return false;
        }

        if (APAJournalMember) {
            const deleteRecord = {
                individualAccount: {
                    accountCustomFields: [
                        {
                            "id": 78,
                            "value":null
                        }
                    ]
                }
            }
    
            await updateIndividualAccount({accountId: APAJournalMember['Account ID'], body: deleteRecord});
        }

        const addRecord = {
            individualAccount: {
                accountCustomFields: [
                    {
                        id: 78,
                        optionValues:[{
                            id:2
                        }]
                    }
                ]
            }
        }

        await updateIndividualAccount({accountId: data, body: addRecord});

        await getAPAJournalMember(programAccountId).then((memberData) => {
            setAPAJournalMember(memberData);    
            setIsLoading(false);           
        });
    }

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <Card.Title style={{borderBottom: '1px solid #c7ddec'}}>
                        APA Journal Member
                    </Card.Title>
                    <Card.Text style={{minHeight: '4rem'}}>
                    {!isLoading ? !APAJournalMember ? (
                        <p>No Member Selected</p>) : (
                        <Fragment>
                            <span style={{fontSize: '1.25rem', lineHeight: '1.2', marginBottom: '0.5rem', fontWeight:'500'}}>{`${APAJournalMember['First Name']} ${APAJournalMember['Last Name']}`}{APAJournalMember['Suffix'].length && (`, ${APAJournalMember['Suffix']}`)}</span><br />
                            {APAJournalMember['Address Line 1']}<br />
                            {APAJournalMember['Address Line 2'] && APAJournalMember['Address Line 2']}
                            <br />{APAJournalMember['City']}, {APAJournalMember['State/Province']} {APAJournalMember['Zip Code']}
                        </Fragment>
                    ) : <Loading />}
                    </Card.Text>

                    <UpdateProgramContact 
                        contactType="APA Journal Member" 
                        updateRecord={updateRecord} />
                        
                </Card.Body>
            </Card>
        </Fragment>
        
    )
}

export default APAJournalMember;