
export function verifyCookie(name) {
    let cookie;
  
    try {
      cookie = document.cookie
        .split('; ')
        .find(row => row.startsWith(`${name}=`))
        .split('=')[1];
    }
    catch (ex) {
      cookie = null;
    }
      
  
    return cookie;
  }