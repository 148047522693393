import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
import { useFormik } from 'formik';
import { getProgramInformation, updateProgramInformation, setAccountCustomFields } from '../../lib/api';
import { sanitizeCompanyAccount } from '../../lib/sanitize-company-account';
import Loading from '../UI/Loading';
import * as Yup from 'yup';
import Error from '../UI/Error';

const ProgramInfo = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [updateComplete, setUpdateComplete] = useState(false);
    const [programInfo, setProgramInfo] = useState();
    const programAccountId = useSelector(state => state.client.clientAccountId);
    const [programName, setProgramName] = useState('');
    const [programWebsite, setProgramWebsite] = useState('');
    const [pgy1Residents, setPgy1Residents] = useState('');
    const [pgy2Residents, setPgy2Residents] = useState('');
    const [pgy3Residents, setPgy3Residents] = useState('');
    const [year1Fellows, setYear1Fellows] = useState('');
    const [year2Fellows, setYear2Fellows] = useState('');
    const [year3Fellows, setYear3Fellows] = useState('');
    const [totalFellowPrograms, setTotalFellowPrograms] = useState('');
    const [programSetting, setProgramSetting] = useState([]);
    const [categoricalResidents, setCategoricalResidents] = useState('');
    const [combinedSubspecialtyResidents, setCombinedSubspecialtyResidents] = useState('');
    const [combinedSubspecialtyResidentTypes, setCombinedSubspecialtyResidentTypes] = useState([]);
    const [learnParticipation, setLearnParticipation] = useState([]);
    const [error, setError] = useState();

    const programSettingOptions = ['Community-Based','Community-based, university-affiliated','University-based','Military'];
    const combinedSubspecialtyResidentOptions = ['Medicine-Pediatrics','Pediatrics-Emergency Medicine','Pediatric Neurology','Pediatrics/Psychiatry/Child Psychiatry','Pediatrics-Anesthesia','Pediatrics-PM&R','Pediatrics-Medical Genetics'];
    const yesNoOptions = ['Yes','No'];

    const setRefs = (data) => {
            setProgramName(data.name || '');
            setProgramWebsite(data.url || '');
            setPgy1Residents(data['PGY1 Residents'] || '');
            setPgy2Residents(data['PGY2 Residents'] || '');
            setPgy3Residents(data['PGY3 Residents'] || '');
            setYear1Fellows(data['Year 1 Fellows'] || '');
            setYear2Fellows(data['Year 2 Fellows'] || '');
            setYear3Fellows(data['Year 3 Fellows'] || '');
            setTotalFellowPrograms(data['Total Pediatric Fellowship Programs'] || '');
            data['Program Setting'] && setProgramSetting(data['Program Setting'] || '');
            setCategoricalResidents(data['Categorical Residents'] || '');
            setCombinedSubspecialtyResidents(data['Combined Subspecialty Residents'] || '');
            setCombinedSubspecialtyResidentTypes(data['Combined Resident Types'] || '');  
            setLearnParticipation(data['LEARN Participation']?.[0] || []);
    }
    
    useEffect(() => {
        if (!programAccountId) {
            return
        }
        const fetchProgramInfo = async () => {
            await getProgramInformation(programAccountId).then((programInformation) => {
                const data = sanitizeCompanyAccount(programInformation);

                setProgramInfo(data);  
                setRefs(data);              
            });
            
        }
        fetchProgramInfo();
    }, [programAccountId]);

    const formSubmitHandler = async (values) => {
        setError(false);
        setIsLoading(true);
        
        const customFieldData = {
            'PGY1 Residents': values.pgy1Residents,
            'PGY2 Residents': values.pgy2Residents,
            'PGY3 Residents': values.pgy3Residents,
            'Year 1 Fellows': values.year1Fellows,
            'Year 2 Fellows': values.year2Fellows,
            'Year 3 Fellows': values.year3Fellows,
            'Total Pediatric Fellowship Programs': values.totalFellowPrograms,
            'Program Setting': values.programSetting,
            'Categorical Residents': values.categoricalResidents,
            'Combined Subspecialty Residents': values.combinedSubspecialtyResidents,
            'Combined Resident Types': values.combinedSubspecialtyResidentTypes,
            'LEARN Participation': values.learnParticipation,
        }

        const customFieldOutput = await setAccountCustomFields(customFieldData);

        const formData = {
            name: values.programName,
            url: values.programWebsite,
            accountCustomFields: customFieldOutput
        }

        const programUpdate = await updateProgramInformation(programAccountId, formData);

        if (programUpdate?.error) {
            setError(`Update Error: ${programUpdate.message}`);
            setIsLoading(false);
            setUpdateComplete(false);
            return;
        }

        if (programUpdate) {
            await getProgramInformation(programAccountId).then((programInformation) => {
                const data = sanitizeCompanyAccount(programInformation);

                setProgramInfo(data);  
                setRefs(data);              
            });
            setUpdateComplete(true);
            setIsLoading(false);
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            programName: programName,
            programWebsite: programWebsite,
            pgy1Residents: pgy1Residents,
            pgy2Residents: pgy2Residents,
            pgy3Residents: pgy3Residents,
            year1Fellows: year1Fellows,
            year2Fellows: year2Fellows,
            year3Fellows: year3Fellows,
            totalFellowPrograms: totalFellowPrograms,
            programSetting: programSetting,
            categoricalResidents: categoricalResidents,
            combinedSubspecialtyResidents: combinedSubspecialtyResidents,
            combinedSubspecialtyResidentTypes: combinedSubspecialtyResidentTypes,
            learnParticipation: learnParticipation
        },
        validationSchema: Yup.object().shape({
            programName: Yup.string()
                .max(300, 'Must be 300 characters or less')
                .required('Required'),
            programWebsite: Yup.string()
                .max(300, 'Must be 300 characters or less'),
            pgy1Residents: Yup.number()
                .required('Required'),
            pgy2Residents: Yup.number()
                .required('Required'),
            pgy3Residents: Yup.number()
                .required('Required'),
            year1Fellows: Yup.number()
                .required('Required'),
            year2Fellows: Yup.number()
                .required('Required'),
            year3Fellows: Yup.number()
                .required('Required'),
            totalFellowPrograms: Yup.number()
                .required('Required'),
            programSetting: Yup.array().of(Yup.string())
                .required('Select at least one Program Setting'),
            categoricalResidents: Yup.number()
                .required('Required'),
            combinedSubspecialtyResidents: Yup.number()
                .required('Required'),
            learnParticipation: Yup.string()
                .required('Required'),
        }),
        onSubmit: async (values) => {          
            await formSubmitHandler(values);
        },
    });
    
    return (
        <Row>
            <Col sm={9} style={{margin:'0 auto'}}>
                {error && <Error message={error} />}
                {updateComplete && !isLoading && <Alert variant="success">Your program information has been updated.</Alert>}
                <h2>Program Information</h2>
                {programInfo && !isLoading ? (
                <Form onSubmit={formik.handleSubmit}>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="programName">Program Name</Form.Label>
                        <Col sm={6}>
                            <Form.Control 
                                id="programName"
                                name="programName"
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.programName} 
                                disabled
                            />
                            {formik.touched.programName && formik.errors.programName ? (
                                <div style={{color:'red'}}>{formik.errors.programName}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="programWebsite">Program Website</Form.Label>
                        <Col sm={6}>
                            <Form.Control 
                                id="programWebsite"
                                name="programWebsite"
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.programWebsite} 
                            />
                            {formik.touched.programWebsite && formik.errors.programWebsite ? (
                                <div style={{color:'red'}}>{formik.errors.programWebsite}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="programSetting">How would you describe your program setting?</Form.Label>
                        <Col md={4}>
                            {programSettingOptions.map((option) => {
                                return (
                                    <Form.Check 
                                        name="programSetting" 
                                        className="programSetting"
                                        key={option} 
                                        type="checkbox" 
                                        label={option} 
                                        value={option}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        {...programSetting.find((element) => element === option) ? {defaultChecked: true} : null}
                                    />
                                )
                            })}
                            {formik.touched.programSetting && formik.errors.programSetting ? (
                                <div style={{color:'red'}}>{formik.errors.programSetting}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="learnParticipation">Does your program want to participate in <a href="https://learn.appd.org" target="_blank" rel="noreferrer">APPD LEARN?</a></Form.Label>
                        <Col md={4}>
                            {yesNoOptions.map((option) => {
                                return (
                                    <Form.Check 
                                        name="learnParticipation" 
                                        className="learnParticipation"
                                        key={option} 
                                        type="radio" 
                                        label={option} 
                                        value={option}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        {...learnParticipation  === option ? {defaultChecked: true} : null}
                                    />
                                )
                            })}
                            {formik.touched.learnParticipation && formik.errors.learnParticipation ? (
                                <div style={{color:'red'}}>{formik.errors.learnParticipation}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Row>
                        <Col>
                            <h2>Residents Information</h2>
                        </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="pgy1Residents">Current Number of PGY1 Residents</Form.Label>
                        <Col md={2}>
                            <Form.Control 
                                id="pgy1Residents"
                                name="pgy1Residents"
                                type="number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.pgy1Residents}
                            />
                            {formik.touched.pgy1Residents && formik.errors.pgy1Residents ? (
                                <div style={{color:'red'}}>{formik.errors.pgy1Residents}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="pgy2Residents">Current Number of PGY2 Residents</Form.Label>
                        <Col md={2}>
                            <Form.Control 
                                id="pgy2Residents"
                                name="pgy2Residents"
                                type="number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.pgy2Residents}
                            />
                            {formik.touched.pgy2Residents && formik.errors.pgy2Residents ? (
                                <div style={{color:'red'}}>{formik.errors.pgy2Residents}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="pgy3Residents">Current Number of PGY3 Residents</Form.Label>
                        <Col md={2}>
                            <Form.Control 
                                id="pgy3Residents"
                                name="pgy3Residents"
                                type="number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.pgy3Residents}
                            />
                            {formik.touched.pgy1Residents && formik.errors.pgy3Residents ? (
                                <div style={{color:'red'}}>{formik.errors.pgy3Residents}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="categoricalResidents">How many categorical pediatric residents do you have in your program?</Form.Label>
                        <Col md={2}>
                            <Form.Control 
                                id="categoricalResidents"
                                name="categoricalResidents"
                                type="number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.categoricalResidents}
                            />
                            {formik.touched.categoricalResidents && formik.errors.categoricalResidents ? (
                                <div style={{color:'red'}}>{formik.errors.categoricalResidents}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="combinedSubspecialtyResidents">How many combined subspecialty (eg., Med-Peds, etc) pediatric residents do you have in your program?</Form.Label>
                        <Col md={2}>
                            <Form.Control 
                                id="combinedSubspecialtyResidents"
                                name="combinedSubspecialtyResidents"
                                type="number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.combinedSubspecialtyResidents}
                            />
                            {formik.touched.combinedSubspecialtyResidents && formik.errors.combinedSubspecialtyResidents ? (
                                <div style={{color:'red'}}>{formik.errors.combinedSubspecialtyResidents}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="combinedSubspecialtyResidentTypes">What combined subspecialty pediatric residents do you have in your program?</Form.Label>
                        <Col md={4}>
                            {combinedSubspecialtyResidentOptions.map((option) => {
                                return (
                                    <Form.Check 
                                        name="combinedSubspecialtyResidentTypes" 
                                        className="combinedSubspecialtyResidentTypes"
                                        key={option} 
                                        type="checkbox" 
                                        label={option} 
                                        value={option}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        {...combinedSubspecialtyResidentTypes.length && combinedSubspecialtyResidentTypes.find((element) => element === option) ? {defaultChecked: true} : null}
                                    />
                                )
                            })}
                            {formik.touched.combinedSubspecialtyResidentTypes && formik.errors.combinedSubspecialtyResidentTypes ? (
                                <div style={{color:'red'}}>{formik.errors.combinedSubspecialtyResidentTypes}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Row>
                        <Col>
                            <h2>Fellows Information</h2>
                        </Col>
                    </Row>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="year1Fellows">Current Number of Year 1 Fellows</Form.Label>
                        <Col md={2}>
                            <Form.Control 
                                id="year1Fellows"
                                name="year1Fellows"
                                type="number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.year1Fellows}
                            />
                            {formik.touched.year1Fellows && formik.errors.year1Fellows ? (
                                <div style={{color:'red'}}>{formik.errors.year1Fellows}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="year2Fellows">Current Number of Year 2 Fellows</Form.Label>
                        <Col md={2}>
                            <Form.Control 
                                id="year2Fellows"
                                name="year2Fellows"
                                type="number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.year2Fellows}
                            />
                            {formik.touched.year2Fellows && formik.errors.year2Fellows ? (
                                <div style={{color:'red'}}>{formik.errors.year2Fellows}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="year3Fellows">Current Number of Year 3 Fellows</Form.Label>
                        <Col md={2}>
                            <Form.Control 
                                id="year3Fellows"
                                name="year3Fellows"
                                type="number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.year3Fellows}
                            />
                            {formik.touched.year1Fellows && formik.errors.year3Fellows ? (
                                <div style={{color:'red'}}>{formik.errors.year3Fellows}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column md={4} htmlFor="year3Fellows">Total number of pediatric fellowship programs</Form.Label>
                        <Col md={2}>
                            <Form.Control 
                                id="totalFellowPrograms"
                                name="totalFellowPrograms"
                                type="number" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.totalFellowPrograms}
                            />
                            {formik.touched.year1Fellows && formik.errors.totalFellowPrograms ? (
                                <div style={{color:'red'}}>{formik.errors.totalFellowPrograms}</div>
                            ) : null}
                        </Col>
                    </Form.Group>
                    <Button type="submit">Save</Button>
                </Form>) : (<Loading />)}
            </Col>
        </Row>
    )
}

export default ProgramInfo;