import { Fragment } from 'react';
import { useSelector  } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import {LinkContainer} from 'react-router-bootstrap';

const MainNav = (props) => {
  const transactionComplete = useSelector(state => state.user.transactionComplete);

    return (
        <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand>
            <img 
              alt="APPD Dues"
              src="/appd-logo@2x.png"
              width="60"
              height="70"
              className="d-inline-block align-top"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              {props.isAuthenticated && (
                <Fragment>
                  {!transactionComplete && <LinkContainer to="/program-information">
                      <Nav.Link>Program Information</Nav.Link>
                  </LinkContainer>}
                  {!transactionComplete && <LinkContainer to="/program-contacts">
                      <Nav.Link>Contact Information</Nav.Link>
                  </LinkContainer>}
                  {!transactionComplete && <LinkContainer to="/list-members">
                      <Nav.Link>Members</Nav.Link>
                  </LinkContainer>}
                  {!transactionComplete && <LinkContainer to="/review">
                      <Nav.Link>Review</Nav.Link>
                  </LinkContainer>}
                  <LinkContainer to="/logout">
                      <Nav.Link>Logout</Nav.Link>
                  </LinkContainer>
                </Fragment>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
}

export default MainNav;