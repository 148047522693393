

export function sanitizeCompanyAccount (accountData) {

    if (!accountData) {
        return false;
    }

    let response = {};

    accountData.accountCustomFields.map((customField) => {
        let value;

        if (customField.optionValues) {
            value = [];
            customField.optionValues.map((option) => {
                return value.push(option.name);
            });
        }
        else {
            value = customField.value;
        }
        return response[customField.name] = value;
    });

    response.accountId = accountData.accountId;
    response.name = accountData.name;
    response.url = accountData.url;

    return response;
}
