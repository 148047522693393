import { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Error from '../UI/Error';
import PaymentOptions from './PaymentOptions';
import Loading from '../UI/Loading';
import { clientActions } from '../../store/client-slice';
import { getCart } from '../../lib/shoppingCartFunctions';

const Payment = () => {
    const dispatch = useDispatch(false);
    const history = useNavigate();
    const cart = useSelector(state => state.client.shoppingCart);
    const shoppingCartId = useSelector(state => state.client.shoppingCartId);
    const [currentView, setCurrentView] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (cart) {
            setLoading(false);
            return;
        }

        const populateCart = async () => {
            const cartData = await getCart(shoppingCartId);
            dispatch(clientActions.setShoppingCart(cartData));   
        }

        populateCart();
    },[shoppingCartId, cart, dispatch])

    useEffect(() => {
        switch (paymentMethod) {
            case 12:
                history('/ach', {replace: true});
                break;
            case 3:
                history('/offline-check', {replace: true});
                break;
            case 4:
                history('/credit', {replace: true});
                break;
            default:
                setCurrentView(<PaymentOptions paymentMethod={updatePaymentMethod} />);
                break;
        }
    },[paymentMethod, history]);
    
    const updatePaymentMethod = (paymentId) => {
        setPaymentMethod(paymentId);
    }

    return ( 
        <Fragment>
            {loading ? <Loading /> : <>
                {!cart ? <Error message="No cart found. Please review your membership before accessing this page" /> : (
                    <div>{currentView}</div>
                )}
            </>
            }
        </Fragment>
    )
}

export default Payment;