import { useEffect, useState, Fragment, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Error from '../UI/Error';
import Form from 'react-bootstrap/Form';
import { userActions } from '../../store/user-slice';
import { addPayment, getCart } from '../../lib/shoppingCartFunctions';

const Credit = () => {
    const dispatch = useDispatch(false);
    const history = useNavigate();
    const cart = useSelector(state => state.client.shoppingCart);
    const shoppingCartId = useSelector(state => state.client.shoppingCartId);
    const subTotal = cart.subTotal;
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [phone, setPhone] = useState();
    const [address, setAddress] = useState();
    const [city, setCity] = useState();
    const [stateProvince, setStateProvince] = useState();
    const [zipPostalCode, setZipPostalCode] = useState();
    const [paymentError, setPaymentError] = useState();
    const [error, setError] = useState();
    const [paymentPending, setPaymentPending] = useState();
    const [cardSubscribed, setCardSubscribed] = useState(true);
    const stateProvinces = useSelector(state => state.stateProvinces.values);

    const neonPay = useMemo(() => {
        const trash = document.getElementsByClassName('NeonPay__session');
        while (trash.length > 0) trash[0].remove();
        return new window.NeonPay('public_7107b891d0668881e3e70bcab53e6095559105304990f27718b07042', '3907');
    },[]);

    const card = useMemo(() => {
        return neonPay.createField('card',{hidePostalCode:true,amount: {subTotal}});
    }, [subTotal, neonPay]);


    useEffect(() => {
        if (!cardSubscribed) return;

        if (card) {
            document.getElementById('cardFields').innerHTML = '';
            card.mount('#cardFields');
            setCardSubscribed(false);
        }
        
    },[card, cardSubscribed]);

    const resetForm = () => {
        card.unmountField('#paymentForm');
        history('/payment', {replace: true});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setPaymentPending(true);
        setPaymentError(false);

        const tokenData = {
            first_name:firstName,
            last_name:lastName,
            email:email,
            phone:phone,
            address_line_1:address,
            address_city:city,
            address_state:stateProvince,
            address_zip:zipPostalCode,
            amount:subTotal
        }

        try {
            await neonPay.createToken(card, tokenData).then((result) => {
                if (!result.token) {
                    return setError('No Payment Token Received');
                }

                const params = {
                    cartId: shoppingCartId,
                    amount: subTotal,
                    payment: 4,
                    token: result.token,
                    email: result.email
                };

                addPayment(params);
  
            });
        }
        catch (ex) {
            setPaymentError(true);
            setPaymentPending(false);
            const message = ex?.message || JSON.stringify(ex);
            setError(message);
            return false;
        }
    }

    useEffect(() => {
        if (!shoppingCartId) return;
        if (paymentError) return;

        const pollCart = async () => {
            const cart = await getCart(shoppingCartId);
    
            if (!cart.payments?.[0]) {
                return 'Pending';
            }
    
            if (['Failed','Canceled'].includes(cart.payments[0].paymentStatus)) {
                setPaymentError(true);
                setError(cart.payments[0].paymentStatus);
            }
    
            if (cart.payments[0].paymentStatus === 'Succeeded') {
                dispatch(userActions.setTransactionComplete(true));
                history('/complete', {replace: true});
            }
           
        }

        const pollInterval = setInterval(() => {
            if (paymentPending) {
                pollCart();
            }
        }, 5000);
        return () => clearInterval(pollInterval);
    },[paymentPending, shoppingCartId, dispatch, history, paymentError]);
    
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const stripAlphaString = (event) => {
        event.target.value = event.target.value.replace(/\D/g,'').substring(0,10);
    }

    return (
        <Fragment>
            <Row>
                <Col md={7} style={{margin:"2rem auto"}}>
                    <h3>Pay with Credit Card</h3>
                    <h4>Total Amount Due: {USDollar.format(cart.totalCharge)}</h4>
                </Col>
            </Row>
            <Row>
                <Col md={7} style={{margin:"2rem auto"}}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="firstName">
                                First Name
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="text" 
                                    onBlur={event => setFirstName(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="lastName">
                                Last Name
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="text" 
                                    onBlur={event => setLastName(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="email">
                                Email
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="email" 
                                    onBlur={event => setEmail(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="phone">
                                Phone
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="phone" 
                                    onChange={event => stripAlphaString(event)}
                                    onBlur={event => setPhone(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="address">
                                Address
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="text" 
                                    onBlur={event => setAddress(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="city">
                                City
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="text" 
                                    onBlur={event => setCity(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="stateProvince">
                                State/Province
                            </Form.Label>
                            <Col md={6}>
                                <Form.Select
                                    onChange={event => setStateProvince(event.target.value)}
                                >
                                    <option value=""></option>
                                    {stateProvinces.length && stateProvinces.map((sp, index) => (
                                        <option 
                                            key={`stateProvince-${index}`} 
                                            value={sp.code}
                                        >
                                            {sp.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="zipPostalCode">
                                Zip/Postal Code
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="text" 
                                    onBlur={event => setZipPostalCode(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Row className="mb-3">
                            <Col md={10}>
                                <div id={'cardFields'}></div>
                            </Col>
                        </Row>
                        {paymentError && (
                            <Row>
                                <Col md={10}>
                                    <Error message={`Payment Processing Error. Please do not attempt another transaction. Send the following code to info@appd.org: ${error}`} />
                                </Col>
                            </Row>
                        )}
                        <Row className="mb-3">
                            <Col md={6}>
                                {paymentPending ? <p>Payment Processing...</p> :
                                    <Button type="submit">Submit Payment</Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col md={6} style={{margin:'0 auto'}}>
                    <Button className={'btn-secondary'} onClick={() => resetForm()}>Choose Another Payment Method</Button> 
                </Col>
            </Row>
        </Fragment>
    )
}

export default Credit;