import { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import LoaderSvg from '../UI/LoaderSvg';
import { findProgramMembers } from '../../lib/api';

import modules from './UpdateProgramContact.module.css';

const UpdateProgramContact = (props) => {
    const [show, setShow] = useState(false);
    const [members, setMembers] = useState();
    const [userData, setUserData] = useState();
    const [term, setTerm] = useState('');
    const [debouncedTerm, setDebouncedTerm] = useState(term);
    const [noResults, setNoResults] = useState();
    const [loadingResults, setLoadingResults] = useState(false);
    const programAccountId = useSelector(state => state.client.clientAccountId);

    useEffect(() => {
        const timer = setTimeout(() => setTerm(debouncedTerm), 1000);
        return () => clearTimeout(timer);
    },[debouncedTerm]);

    useEffect(() => {
        if(term !== ''){
            setMembers(null);
            setLoadingResults(true);
            const getMembers = async () => {
                const data = {
                    accountId: programAccountId,
                    lastName: term
                }

                await findProgramMembers(data).then((memberData) => {
                    if (!memberData) {
                        setNoResults(true);
                        setLoadingResults(false);
                        return false;
                    }
                    setMembers(memberData);
                    setNoResults(false);
                    setLoadingResults(false);
                });
            }

            getMembers();
        }
    }, [term, programAccountId]);


    const handleClose = () => {
        setShow(false);
        setMembers(false);
        setDebouncedTerm('');
        setLoadingResults(false);
        setNoResults(false);
    }
    const handleShow = () => setShow(true);

    const updateParent = () => {
        handleClose();
        if (members) {
            props.updateRecord(userData);
        }
        setMembers(null);
    }

    const updateSelection = (event, id) => {
        document.querySelectorAll('div').forEach((el) => {
            el.classList.remove(modules.selectedContact);
        });
        event.target.classList.add(modules.selectedContact);
        setUserData(id);
    }

    return (
        <Fragment>
            <Button variant="primary" onClick={handleShow}>
                Change Recipient
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update {props.contactType}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Form>
                        <Form.Control 
                            placeholder="Search for a member by last name"
                            onChange={e => setDebouncedTerm(e.target.value)}
                        />
                    </Form>
                    {noResults && <p>No results found</p>}
                    {loadingResults && <p>Loading {<LoaderSvg />}</p>}
                    {members && (
                        <Row className={modules.searchResultBlock}>
                            <h5>Select a Member</h5>
                            {members.map((member) => {
                            return (
                            
                                <div 
                                    key={member['Account ID']}
                                    className={modules.searchResultItem}
                                    onClick={(e) => updateSelection(e, member['Account ID'])}
                                >
                                    {member['First Name']} {member['Last Name']}{member['Suffix'].length && (`, ${member['Suffix']}`)}
                                </div>
                           
                            )})}
                        </Row>
                    )}
                </Modal.Body>

                <Modal.Footer>
                    <Button 
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                    <Button variant="primary" onClick={updateParent}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default UpdateProgramContact;