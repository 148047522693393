import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import MemberForm from '../Members/MemberForm';
import { addIndividualAccount, getCustomFields } from '../../lib/api';
import Loading from '../UI/Loading';
import { clientActions } from '../../store/client-slice';

const AddMember = (props) => {
    const dispatch = useDispatch(false);
    const history = useNavigate();
    const [formLoading, setFormLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);

    const region = useSelector(state => state.user.region);
    const programAccountId = useSelector(state => state.client.clientAccountId);


    const handleClose = () => {
        setError(false);
        setFormLoading(false);
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const addRecord = async (formData) => {
        setError(false);
        setFormLoading(true);

        await getCustomFields().then(async (customFields) => {
            try {
                const data = {
                    individualAccount: {
                        primaryContact: {
                            firstName: formData.firstName,
                            lastName: formData.lastName,
                            salutation: formData.salutation,
                            suffix: formData.degree,
                            title: formData.jobTitle,
                            addresses: [{
                                isPrimaryAddress: true,
                                addressLine1: formData.addressLine1,
                                addressLine2: formData.addressLine2,
                                city: formData.city,
                                zipCode: formData.zipCode,
                                stateProvince: {
                                    code: formData.stateProvince
                                },
                                phone1: formData.phone
                            }],
                            email1: formData.email,
                        },
                        accountCustomFields: [
                            {
                                name: 'Alternate Member Type',
                                id: customFields.find(field => field.name === 'Alternate Member Type').id,
                                optionValues:  formData.alternateMemberType ? [{ id:customFields.find(field => field.name === 'Alternate Member Type').optionValues.find(option => option.name === formData.alternateMemberType).id }] : null
                            },
                            {
                                name: 'Sub-Specialty',
                                id: customFields.find(field => field.name === 'Sub-Specialty').id,
                                optionValues:  formData.subSpecialty ? [{ id: customFields.find(field => field.name === 'Sub-Specialty').optionValues.find(option => option.name === formData.subSpecialty).id }] : null
                            },
                            {
                                name: 'Other Job Specialty',
                                id: customFields.find(field => field.name === 'Other Job Specialty').id,
                                optionValues:  formData.alternateSubspecialty ? [{ id:customFields.find(field => field.name === 'Other Job Specialty').optionValues.find(option => option.name === formData.alternateSubspecialty).id }] : null
                            },
                            {
                                name: 'Region',
                                id: customFields.find(field => field.name === 'Region').id,
                                optionValues: [{ id:customFields.find(field => field.name === 'Region').optionValues.find(option => option.name === region).id }] 
                            },
                            {
                                name: 'Title',
                                id: customFields.find(field => field.name === 'Title').id,
                                value: formData.title 
                            }
                        ],
                        company: {
                            id: programAccountId,
                            status: "ACTIVE"
                        }
                    }
                }

                const response = await addIndividualAccount(data, programAccountId, formData.isPrimaryContact);

                if (response.code === 'success' || response.code === 'duplicate') {
                    const memberObj = {
                        'First Name': formData.firstName,
                        'Last Name': formData.lastName,
                        'Salutation': formData.salutation,
                        'Suffix': formData.degree,
                        'Job Title': formData.jobTitle,
                        'Title' : formData.title,
                        'Address Line 1': formData.addressLine1,
                        'Address Line 2': formData.addressLine2,
                        'City': formData.city,
                        'State/Province': formData.stateProvince,
                        'Zip Code': formData.zipCode,
                        'Phone 1 Full Number (F)': formData.phone,
                        'Email 1': formData.email,
                        'Sub-Specialty': formData.subSpecialty,
                        'Alternate Member Type': formData.alternateMemberType,
                        'Other Job Specialty': formData.alternateSubspecialty
                    }
                    const memberData = props.members;
                    memberData.push(memberObj);
                    props.setMembers(memberData);

                    dispatch(clientActions.setShoppingCartStale(true));
                    history(0);
                }
                else {
                    setFormLoading(false);
                    setError(`Error adding member: ${response.message}`);
                }
            }
            catch (ex) {
                setFormLoading(false);
                setError(ex.message);
            }

        });
    }
    
    return (
        <Fragment>
            <Button 
                variant="primary" 
                size="lg"
                onClick={handleShow}
            >Add {props.membershipType}</Button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add New {props.membershipType} Member</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <Alert variant="danger">{error}</Alert>}
                    {formLoading ? <Loading /> : (
                    <MemberForm 
                        membershipType={props.membershipType}
                        submitForm={addRecord}
                    />
                    )}
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button    
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default AddMember;