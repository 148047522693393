import { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../UI/Loading';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Error from '../UI/Error';
import LoaderSvg from '../UI/LoaderSvg';
import { validateCompletion, getProgramMembers } from '../../lib/api';
import { buildCart, deleteInactiveCarts, setMemberships, getCarts } from '../../lib/shoppingCartFunctions';
import { clientActions } from '../../store/client-slice';
import {LinkContainer} from 'react-router-bootstrap';

const Review = () => {
    const dispatch = useDispatch(false);
    const programAccountId = useSelector(state => state.client.clientAccountId);
    const shoppingCartId = useSelector(state => state.client.shoppingCartId);
    const shoppingCartStale = useSelector(state => state.client.shoppingCartStale);
    const membershipTerms = useSelector(state => state.membershipTerms.terms);
    const memberRoles = useSelector(state => state.client.memberRoles);
    const region = useSelector(state => state.client.region);
    const membershipCustomFields = useSelector(state => state.membershipCustomFields);
    const [members, setMembers] = useState();
    const [loading, setLoading] = useState(true);
    const [validated, setValidated] = useState();
    const [error, setError] = useState();
    const [cartLoading, setCartLoading] = useState(true);
    const [cartCount, setCartCount] = useState();
    const [cartRequestSent, setCartRequestSent] = useState();
    const [deleteCheckComplete, setDeleteCheckComplete] = useState();

    useEffect(() => {
        if (!programAccountId || !memberRoles) {
            return;
        }

        const getValidated = async () => {
            return await validateCompletion(programAccountId, memberRoles);
        }

        getValidated().then((response) => {
            if (response.status === false){
                let valMsg = '';
                response.message.map((account) => {
                    valMsg += `<h4>${account.name}</h4><ul>`;
                    account.message.map((item) => {
                        return valMsg += `<li>${item}</li>`;
                    });
                    valMsg += '</ul>';
                    return null;
                });
                setError(`Please review the following the following items before submitting your dues renewal: ${valMsg}`);
                setLoading(false);
                return setValidated(false);
            }
            
            setError(false);
            setValidated(true);
        });

    },[programAccountId, memberRoles]);

    useEffect(() => {
        if (!programAccountId || !validated) {
            return;
        }

        const getMembers = async () => {
            await getProgramMembers(programAccountId, memberRoles).then(async (memberData) => {
                if (!memberData) {
                    return false;
                }
                const { memberships } = setMemberships(memberData);

                setMembers(memberships);
                setLoading(false);
            });
        }

        if (!members) {
            getMembers();
        }
    },[programAccountId, members, memberRoles, validated]);

    useEffect(() => {
        if (!members || !validated) {
            return;
        }

        if (validated.status === false) {
            return;
        }

        if (cartRequestSent) return;

        const params = {
            accountId: programAccountId,
            members: members,
            membershipTerms: membershipTerms,
            membershipCustomFields: membershipCustomFields,
            region: region
        }

        const updateCart = async () => {
            await deleteInactiveCarts(programAccountId).then(async () => {
                setDeleteCheckComplete(true);
                await buildCart(params).then((response) => {
                    if (!response) {
                        setError('Cart could not be created. Please try reloading this page');
                    }

                    dispatch(clientActions.setMembers(members.sort((a,b) => memberRoles.indexOf(a['Job Title']) - memberRoles.indexOf(b['Job Title']))));
                    
                    setCartRequestSent(true);
                });
            });
        }

        if (shoppingCartStale || !cartRequestSent) {
            updateCart();
            return;
        }
        
    },[programAccountId, shoppingCartId, members, membershipTerms, membershipCustomFields, region, shoppingCartStale, validated, memberRoles, dispatch, cartRequestSent]);

    useEffect(() => {
        if (!programAccountId) return;

        const getCartCount = async () => {
            if (cartCount) return;

            const carts = await getCarts(programAccountId);

            if (carts?.length) setCartCount(carts[carts.length -1]['Shopping Cart ID']);
        }

        const pollCarts = async () => {
            if (!deleteCheckComplete) return;

            const carts = await getCarts(programAccountId);

            if (carts?.[carts.length - 1]?.['Shopping Cart ID'] !== cartCount || carts?.length === 1) {
                const cart = carts[carts.length - 1];
                dispatch(clientActions.setShoppingCartId(cart['Shopping Cart ID']));       
                dispatch(clientActions.setShoppingCartStale(false));
                setCartLoading(false);
            }
        }   

        getCartCount();

        const cartsInterval = setInterval(() => {
            if (cartLoading) {
                pollCarts();
            }
        }, 10000);
        return () => clearInterval(cartsInterval);
        
        
    }, [cartLoading, dispatch, programAccountId, cartCount, shoppingCartStale, deleteCheckComplete]);

    return (
        <Fragment>
            <h1>Review</h1>
            {error ? <Error message={error} /> : (
                <Fragment>
                    <h2>{members && members.length} Members</h2>
                    {loading ? <Loading /> : (
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Member Name</th>
                                    <th>Title</th>
                                    <th>Membership Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                            {members.map((member, idx) => {
                                return (
                                    <tr key={`member-${idx}`}>
                                        <td>{member['First Name']} {member['Last Name']}{member['Suffix'] && `, ${member['Suffix']}`}</td>
                                        <td>{member['Job Title']}</td>
                                        <td>{member.covered ? '$0.00' : 
                                            `$${parseInt(process.env.REACT_APP_SUBMEMBER_FEE).toFixed(2)}`}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    )}
                    {cartLoading && validated ? <div>Cart Loading <LoaderSvg /></div> : validated && (
                        <Row>
                            <Col sm="6">
                                <LinkContainer to='/payment'>
                                    <Button>Continue to Payment</Button>
                                </LinkContainer>
                            </Col>
                        </Row>
                    )}
                </Fragment>
            )}
        </Fragment>
    )
}

export default Review;