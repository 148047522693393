export const getCart = async (cartId) => {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken =
    "Basic " + new Buffer.from(client + ":" + apiKey).toString("base64");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_NEON_BASE_URI}orders/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
      }
    );

    if (!response.ok) {
      return null;
    }
    return await response.json();
  } catch (ex) {
    return null;
  }
};

export async function getCarts(accountId) {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken =
    "Basic " + new Buffer.from(client + ":" + apiKey).toString("base64");

  try {
    const response = await fetch(
      `${process.env.REACT_APP_NEON_BASE_URI}orders/search`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        method: "POST",
        body: JSON.stringify({
          pagination: {
            pageSize: 20,
          },
          searchFields: [
            {
              field: "Account ID",
              operator: "EQUAL",
              value: accountId,
            },
            {
              field: "Transaction Status",
              operator: "EQUAL",
              value: "DEFERRED",
            },
          ],
          outputFields: [
            "Shopping Cart ID",
            "Account ID",
            "Company Name",
            "Transaction Status",
            "Transaction Date",
            "Transaction Item Description",
            "Membership Enrollment Date",
            "Membership Expiration Date",
            "Membership Code",
            "Membership Total",
            "Membership Enrollment ID",
          ],
        }),
      }
    );
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();

    if (responseData.length === 0 || !responseData?.searchResults) {
      return null;
    }

    const duesCarts = responseData.searchResults.filter(
      (cart) => cart["Transaction Item Description"].indexOf("Tier") !== false
    );

    return duesCarts;
  } catch (ex) {
    return null;
  }
}

export const deleteInactiveCarts = async (accountId) => {
  try {
    const orphanCarts = await getCarts(accountId);

    const deleteAll = orphanCarts.map(async (cart) => {
      return await deleteCart(cart["Shopping Cart ID"]);
    });
    const results = await Promise.all(deleteAll);

    return results;
  } catch (ex) {
    return null;
  }
};

export const buildCart = async (params) => {
  const controller = new AbortController();
  const { signal } = controller;
  const {
    accountId,
    members,
    membershipTerms,
    membershipCustomFields,
    region,
  } = params;

  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken =
    "Basic " + new Buffer.from(client + ":" + apiKey).toString("base64");

  let cartSubMembers = [];
  

  const today = new Date();
  const currentDate = today.toISOString().substring(0, 10);
  const { memberships, membershipLevel } = setMemberships(members);
  const membership = membershipTerms.filter(
    (term) => term.level === membershipLevel
  );

  memberships.map((member) => {
    let jobRoles = [];
    membershipCustomFields.jobRole.values.map((role) => {
      if (role.name === member["Job Title"]) {
        jobRoles.push({id:role.id});
      }
      return null;
    });

    const subRecord = {
      membershipTerm: {
        id: member.covered
          ? membership[0].coveredTermId
          : membership[0].paidTermId,
      },
      accountId: member["Account ID"],
      termStartDate: process.env.REACT_APP_TERM_START_DATE,
      termEndDate: process.env.REACT_APP_TERM_END_DATE,
      transactionDate: currentDate,
      fee: member.covered ? 0 : process.env.REACT_APP_SUBMEMBER_FEE,
      termDuration: 1,
      termUnit: "YEAR",
      membershipCustomFields: [
        {
          name: 'Job Role',
          id: membershipCustomFields.jobRole.id,
          optionValues: jobRoles,
        },
      ],
    };
    return cartSubMembers.push(subRecord);
  });

  let totalCharge = parseInt(membership[0].fee);

  cartSubMembers.map((subMember) => {
    totalCharge += parseInt(subMember.fee);

    return true;
  });

  let regionDues = setRegionDues(region, totalCharge, accountId);

  totalCharge += regionDues.amount;

  fetch(`${process.env.REACT_APP_NEON_BASE_URI}orders`, {
    signal: signal,
    headers: {
      "Content-Type": "application/json",
      Authorization: authToken,
    },
    method: "POST",
    body: JSON.stringify({
      accountId: accountId,
      orderDate: currentDate,
      payLater: true,
      needShipping: false,
      totalCharge: totalCharge,
      donations: [regionDues],
      memberships: [
        {
          membershipTerm: {
            id: membership[0].id,
          },
          accountId: accountId,
          termStartDate: process.env.REACT_APP_TERM_START_DATE,
          termEndDate: process.env.REACT_APP_TERM_END_DATE,
          transactionDate: currentDate,
          fee: membership[0].fee,
          termDuration: 1,
          termUnit: "YEAR",
          subMembers: cartSubMembers,
        },
      ],
    }),
  }).then((response) => {
    return response.json();
  }).catch((e) => {
    return { error: true, message: e };
  });

  setTimeout(() => controller.abort(), 2000);

  return true;
};

export const addPayment = async (params) => {
  const controller = new AbortController();
  const { signal } = controller;
  const { cartId, amount, payment } = params;

  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken =
    "Basic " + new Buffer.from(client + ":" + apiKey).toString("base64");

  const today = new Date();
  const currentDate = today.toISOString().substring(0, 10);
  let paymentDetail = {};

  switch (payment) {
    case 3:
      paymentDetail.check = {};
      break;
    case 4:
      paymentDetail.creditCardOnline = {
        token: params.token,
        cardHolderEmail: params.email,
      };
      break;
    case 12:
      paymentDetail.ach = {
        token: params.token,
      };
      break;
    default:
      break;
  }

  try {
    fetch(
      `${process.env.REACT_APP_NEON_BASE_URI}orders/${cartId}/payments`,
      {
        signal: signal,
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        method: "POST",
        body: JSON.stringify({
          amount: amount,
          tenderType: payment,
          receivedDate: currentDate,
          ...(paymentDetail.check && { check: paymentDetail.check }),
          ...(paymentDetail.ach && { ach: paymentDetail.ach }),
          ...(paymentDetail.creditCardOnline && {
            creditCardOnline: paymentDetail.creditCardOnline,
          }),
        }),
      }
    ).then((response) => {
      return response.json();
    }).catch((e) => {
      return { error: true, message: e };
    });

    setTimeout(() => controller.abort(), 2000);

    
  } catch (ex) {
    return { error: true, message: ex };
  }
};

export const setMemberships = (members) => {
  const memberCount = members.length;
  let membershipLevel,
    pdCount = 0,
    dcCount = 0,
    dioCount = 0,
    vceCount = 0,
    apdCount = 0,
    fpdCount = 0,
    prpcCount = 0,
    pfpcCount = 0,
    coveredCount = 0;

  members.map((member) => {
    switch (member["Job Title"]) {
      case "Program Director":
        if (pdCount === 0) {
          pdCount++;
          coveredCount++;
          member.covered = true;
        }
        break;
      case "Department Chair":
        if (dcCount === 0) {
          dcCount++;
          coveredCount++;
          member.covered = true;
        }
        break;
      case "Designated Institutional Official":
        if (dioCount === 0) {
          dioCount++;
          coveredCount++;
          member.covered = true;
        }
        break;
      case "Vice Chair of Education":
        if (vceCount === 0) {
          vceCount++;
          coveredCount++;
          member.covered = true;
        }
        break;
      case "Associate Residency Program Director":
        if (apdCount === 0) {
          apdCount++;
          coveredCount++;
          member.covered = true;
        }
        break;
      case "Subspecialty Program Director":
        if (fpdCount === 0) {
          fpdCount++;
          coveredCount++;
          member.covered = true;
        }
        break;
      case "Pediatric Residency Program Coordinator":
        if (prpcCount === 0) {
          prpcCount++;
          coveredCount++;
          member.covered = true;
        }
        break;
      case "Subspecialty Program Coordinator":
        if (pfpcCount === 0) {
          pfpcCount++;
          coveredCount++;
          member.covered = true;
        }
        break;
      case "Chief Resident":
        coveredCount++;
        member.covered = true;
        break;
      case "Rising Chief Resident":
        coveredCount++;
        member.covered = true;
        break;
      case "Resident":
        coveredCount++;
        member.covered = true;
        break;
      case "Resident (PGY-1)":
        coveredCount++;
        member.covered = true;
        break;
      case "Resident (PGY-2)":
        coveredCount++;
        member.covered = true;
        break;
      case "Resident (PGY-3)":
        coveredCount++;
        member.covered = true;
        break;
      case "Resident (PGY-4)":
        coveredCount++;
        member.covered = true;
        break;
      default:
        member.covered = false;
    }
    return null;
  });

  console.log(`Member Count: ${memberCount} Covered Count: ${coveredCount}`);
  const netMemberCount = memberCount - coveredCount;

  switch (true) {
    case netMemberCount < 11:
      membershipLevel = "Tier 1";
      break;
    case netMemberCount < 21:
      membershipLevel = "Tier 2";
      break;
    case netMemberCount < 41:
      membershipLevel = "Tier 3";
      break;
    case netMemberCount < 53:
      membershipLevel = "Tier 4";
      break;
    case netMemberCount >= 53:
      membershipLevel = "Tier 5";
      break;
    default:
  }

  if (membershipLevel !== "Tier 1") {
    members.map((member) => {
      return (member.covered = true);
    });
  }

  return {
    memberships: members,
    membershipLevel: membershipLevel,
    totalMembers: memberCount,
    coveredMembers: coveredCount,
  };
};

const setRegionDues = (region, cartTotal, programAccountId) => {
  let regionDuesAmount;
  const today = new Date();
  const currentDate = today.toISOString().substring(0, 10);

  switch (region.toLowerCase()) {
    case "mid-atlantic":
      regionDuesAmount = 250;
      break;
    case "mid-america":
      regionDuesAmount = 200;
      break;
    case "midwest":
      regionDuesAmount = 150;
      break;
    case "northeast":
      regionDuesAmount = cartTotal * 0.1;
      break;
    case "new york":
      regionDuesAmount = cartTotal * 0.1;
      break;
    case "southeast":
      regionDuesAmount = 250;
      break;
    case "southwest":
      regionDuesAmount = 250;
      break;
    case "western":
      regionDuesAmount = 200;
      break;
    default:
      regionDuesAmount = 0;
  }

  const donationObject = {
    campaign: {
      id: 4,
    },
    accountId: programAccountId,
    amount: regionDuesAmount,
    date: currentDate,
  };

  return donationObject;
};

const deleteCart = async (cartId) => {
  const delay = (ms = 1000) => new Promise((r) => setTimeout(r, ms));
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken =
    "Basic " + new Buffer.from(client + ":" + apiKey).toString("base64");

  try {
    await delay();
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URI}neon/orders/delete/${cartId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken,
        },
        method: "POST",
      }
    );

    return response;
  } catch (ex) {
    console.log("delete Error:", ex);
    return null;
  }
};
