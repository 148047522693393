import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    isLoggedIn: false, 
    accountId: 0, 
    isAdmin: 0, 
    region: null,
    transactionComplete: false,
    email: null
  },
  reducers: {
    setIsLoggedIn(state, action) {
      state.isLoggedIn = true;
      state.accountId = action.payload;
    },
    setIsAdmin(state, action) {
      state.isAdmin = action.payload;
    },
    setRegion(state, action) {
      state.region = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
    setTransactionComplete(state, action) {
      state.transactionComplete = action.payload;
    },
    clearState(state, action) {
      if (action === 'CLEAR_SESSION') {
        state = undefined;
      }
    },
  },
});

export const userActions = userSlice.actions; 

export default userSlice.reducer;