import { useDispatch } from 'react-redux';
import { userActions } from '../../store/user-slice';
import { clientActions } from '../../store/client-slice';
import { subspecialtiesActions } from '../../store/subspecialties-slice';


const Logout = () => {
  const dispatch = useDispatch();

  // delete cookie
  const date = new Date();
  document.cookie = `neon_access_token=;expires=${date.toGMTString()};domain=${process.env.REACT_APP_DOMAIN}`;
  document.cookie = `auth_code=;expires=${date.toGMTString()};domain=${process.env.REACT_APP_DOMAIN}`;
  // delete redux
  dispatch(clientActions.clearState('CLEAR_SESSION'));
  dispatch(userActions.clearState('CLEAR_SESSION'));
  dispatch(subspecialtiesActions.clearState('CLEAR_SESSION'));
  // logout neon and redirect
  window.location= `https://${process.env.REACT_APP_NEON_API_USER}.app.neoncrm.com/np/logout.do?targetUrl=${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_DOMAIN}:${process.env.REACT_APP_PORT}`;

  return null;
};

export default Logout;