import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../UI/Loading';
import Card from 'react-bootstrap/Card';
import UpdateProgramContact from '../Modal/UpdateProgramContact';
import { getCurriculumSurveyMember, updateIndividualAccount } from '../../lib/api';

const CurriculumSurveyMember = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [curriculumSurveyMember, setCurriculumSurveyMember] = useState();
    const programAccountId = useSelector(state => state.client.clientAccountId);

    useEffect(() => {
        if (!programAccountId) {
            return
        }
        const fetchProgramInfo = async () => {
            await getCurriculumSurveyMember(programAccountId).then((memberData) => {
                setCurriculumSurveyMember(memberData);    
                setIsLoading(false);           
            });
            
        }
        fetchProgramInfo();
    }, [programAccountId]);

    const updateRecord = async (data) => {
        setIsLoading(true);

        if (!data) {
            return false;
        }

        if (curriculumSurveyMember) {
            const deleteRecord = {
                individualAccount: {
                    accountCustomFields: [
                        {
                            "id": 102,
                            "value":null
                        }
                    ]
                }
            }
    
            await updateIndividualAccount({accountId: curriculumSurveyMember['Account ID'], body: deleteRecord});
        }

        const addRecord = {
            individualAccount: {
                accountCustomFields: [
                    {
                        id: 102,
                        optionValues:[{
                            id:357
                        }]
                    }
                ]
            }
        }

        await updateIndividualAccount({accountId: data, body: addRecord});

        await getCurriculumSurveyMember(programAccountId).then((memberData) => {
            setCurriculumSurveyMember(memberData);    
            setIsLoading(false);           
        });
    }

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <Card.Title style={{borderBottom: '1px solid #c7ddec'}}>
                        Curriculum Survey Member
                    </Card.Title>
                    <Card.Text style={{minHeight: '4rem'}}>
                    {!isLoading ? !curriculumSurveyMember ? (
                        <p>No Member Selected</p>) : (
                        <Fragment>
                            <span style={{fontSize: '1.25rem', lineHeight: '1.2', marginBottom: '0.5rem', fontWeight:'500'}}>{`${curriculumSurveyMember['First Name']} ${curriculumSurveyMember['Last Name']}`}{curriculumSurveyMember['Suffix'].length && (`, ${curriculumSurveyMember['Suffix']}`)}</span><br />
                            {curriculumSurveyMember['Address Line 1']}<br />
                            {curriculumSurveyMember['Address Line 2'] && curriculumSurveyMember['Address Line 2']}
                            <br />{curriculumSurveyMember['City']}, {curriculumSurveyMember['State/Province']} {curriculumSurveyMember['Zip Code']}
                        </Fragment>
                    ) : <Loading />}
                    </Card.Text>

                            <UpdateProgramContact 
                                contactType="Curriculum Survey Member" 
                                updateRecord={updateRecord} />
                        
                </Card.Body>
            </Card>
        </Fragment>
    )
}

export default CurriculumSurveyMember;