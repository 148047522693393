import { createSlice } from '@reduxjs/toolkit';

const stateProvincesSlice = createSlice({
  name: 'stateProvinces',
  initialState: {
    values: null
  },
  reducers: {
    setStateProvinces(state, action) {
      state.values = action.payload;
    },
    clearState(state, action) {
      if (action === 'CLEAR_SESSION') {
        state = undefined;
      }
    },
  },
});

export const stateProvincesActions = stateProvincesSlice.actions; 

export default stateProvincesSlice.reducer;