import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch  } from 'react-redux';
import jwt_decode from 'jwt-decode';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import MainNav from './components/Nav/MainNav';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import Logout from './components/Login/Logout';
import Authenticate from './components/Login/Authenticate';
import ProgramInfo from './components/ProgramInfo/ProgramInfo';
import ProgramContacts from './components/ProgramInfo/ProgramContacts';
import ListMembers from './components/Members/ListMembers';
import Review from './components/ReviewPayment/Review';
import Payment from './components/Payment/Payment';
import AlreadyPaid from './components/UI/AlreadyPaid';
import Complete from './components/Payment/Complete';
import NotFound from './components/UI/NotFound';
import Credit from './components/Payment/Credit';
import ACH from './components/Payment/ACH';
import OfflineCheck from './components/Payment/OfflineCheck';

import { getIndividualAccount, getCustomFields, getMembershipStatus, getStateProvinces, getProgramInformation } from './lib/api';
import { verifyCookie } from './lib/verify-cookies';

import { clientActions } from './store/client-slice';
import { userActions } from './store/user-slice';
import { subspecialtiesActions } from './store/subspecialties-slice';
import { stateProvincesActions } from './store/state-provinces-slice';

import './App.css';


window.Buffer = window.Buffer || require('buffer').Buffer;

function App() {
  const dispatch = useDispatch(false);
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [unauthorized, setUnauthorized] = useState();
  const subspecialties = useSelector(state => state.subspecialties.subspecialties);
  const transactionComplete = useSelector(state => state.user.transactionComplete);
  const clientAccountId = useSelector(state => state.client.clientAccountId);
  const clientName = useSelector(state => state.client.clientName);

  useEffect(() => {
    const authCookie = verifyCookie('neon_access_token');
    const clientCookie = verifyCookie('auth_code');
    if (authCookie && clientCookie && !isAuthenticated) {
      dispatch(userActions.setIsLoggedIn(authCookie));
      setIsAuthenticated(true);
      const jwToken = jwt_decode(clientCookie);
      const storeData = {            
        apiKey: jwToken.apiKey,
        neonClientName: jwToken.neonClientName,
        neonOauthId: jwToken.neonOauthId,
        neonOauthSecret: jwToken.neonOauthSecret
      };

      const getUserAccount = async (individualAccountId) => {
        const individualAccount = await getIndividualAccount(individualAccountId);
  
        if (!individualAccount.company.id) {
          return false;
        }

        return individualAccount;
      }
      
      getUserAccount(authCookie).then((account) => {
        if (account) {
          dispatch (clientActions.setNeonApiKey(storeData));
          dispatch(clientActions.setNeonAccountId(account.company.id));

          const regionValue = account.accountCustomFields.filter(item => item.name === 'Region')[0].optionValues[0].name;
          dispatch(userActions.setRegion(regionValue));
          dispatch(userActions.setEmail(account.primaryContact.email1));
          
        }
        else {
          setUnauthorized(true);
        }
      });
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (clientAccountId && ! clientName) {
      const getProgram = async () => {
        const getAccount = await getProgramInformation(clientAccountId);
        console.log(getAccount.accountCustomFields)

        let region = '';
        getAccount.accountCustomFields.map((field) => {
          if (field.name === 'Region') {
            region = field.optionValues[0].name;
          }

          return region;
        });
        
        return {name: getAccount.name, region: region};
      }

      getProgram().then((props) => {
        dispatch(clientActions.setClientName(props.name));
        dispatch(clientActions.setRegion(props.region));
      })
    }
  }, [clientAccountId, clientName, dispatch]);

  useEffect(() => {

    const getSubspecialties = async () => {
      const customFields =  await getCustomFields();
      const subspecialtiesObject = customFields.filter(o => o.name === 'Sub-Specialty');

      return subspecialtiesObject[0].optionValues;
    }

    if (!subspecialties) {
      getSubspecialties().then((subspecialties) => {
        dispatch(subspecialtiesActions.setSubspecialties(subspecialties));
      });
    }
  },[dispatch, subspecialties]);

  useEffect(() => {
    if (!clientAccountId) {
      return;
    }

    let isSubscribed = true;

    const getMostRecentMembership = async () => {
      const membershipIsCurrent = await getMembershipStatus(clientAccountId);
      if (membershipIsCurrent) {
        dispatch(userActions.setTransactionComplete(true));
      }
    }

    if (isSubscribed) {
      getMostRecentMembership();
      isSubscribed = false;
    }
  }, [clientAccountId, dispatch]);

  useEffect(() => {
    let isSubscribed = true;

    const getStates = async () => {
        await getStateProvinces().then((sp) => {
          dispatch(stateProvincesActions.setStateProvinces(sp));
        });
    }

    if (isSubscribed) {
        getStates();
    }
}, [dispatch]);

  const isAuth = useSelector(state => state.user.isLoggedIn);

  return (
    <div className="App">
      {unauthorized && <h3>Unauthorized User</h3>}
      <MainNav isAuthenticated={isAuth} />

      <Container style={{margin: "2rem auto"}}>
        <Row>
          <Col sm={9} style={{margin: '2rem auto'}}>
            {clientName && <h3>Program: {clientName}</h3>}
          </Col>
        </Row>
        <Routes>
          <Route path="/" element={<Home isAuthenticated={isAuth} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/authenticate" element={<Authenticate />} />
          <Route path="/program-information" element={!transactionComplete ? <ProgramInfo /> : <AlreadyPaid />} />
          <Route path="/program-contacts" element={!transactionComplete ? <ProgramContacts /> : <AlreadyPaid />} />
          <Route path="/list-members" element={!transactionComplete ? <ListMembers /> : <AlreadyPaid />} />
          <Route path="/review" element={!transactionComplete ? <Review /> : <AlreadyPaid />} />
          <Route path="/payment" element={!transactionComplete ? <Payment /> : <AlreadyPaid />} />
          <Route path="/credit" element={!transactionComplete ? <Credit /> : <AlreadyPaid />} />
          <Route path="/ach" element={!transactionComplete ? <ACH /> : <AlreadyPaid />} />
          <Route path="/offline-check" element={!transactionComplete ? <OfflineCheck /> : <AlreadyPaid />} />
          <Route path="/complete" element={transactionComplete ? <Complete /> : <NotFound />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<h1>404</h1>} />
        </Routes>
      </Container>
    </div>
  );
}

export default App;
