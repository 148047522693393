import { useState, Fragment, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Error from '../UI/Error';
import Form from 'react-bootstrap/Form';
import { userActions } from '../../store/user-slice';
import { addPayment, getCart } from '../../lib/shoppingCartFunctions';

const ACH = (props) => {
    const dispatch = useDispatch(false);
    const history = useNavigate();
    const cart = useSelector(state => state.client.shoppingCart);
    const shoppingCartId = useSelector(state => state.client.shoppingCartId);
    const subTotal = cart.subTotal;
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [routingNumber, setRoutingNumber] = useState();
    const [accountNumber, setAccountNumber] = useState();
    const [paymentError, setPaymentError] = useState();
    const [accountType, setAccountType] = useState();
    const [error, setError] = useState();
    const [paymentPending, setPaymentPending] = useState();

    const neonPay = useMemo(() => {
        const trash = document.getElementsByClassName('NeonPay__session');
        while (trash.length > 0) trash[0].remove();
        return new window.NeonPay('public_7107b891d0668881e3e70bcab53e6095559105304990f27718b07042', '3907');
    },[]);

    const resetForm = () => {
        history('/payment', {replace: true});
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setPaymentError(false);
        setPaymentPending(true);

        const tokenData = {
            routing_number:routingNumber,
            account_number:accountNumber,
            account_holder_first_name:firstName,
            account_holder_last_name:lastName,
            account_type:accountType,
            country:'US'
        }

        try {
            await neonPay.createToken('bank_account', tokenData).then((result) => {
                if (!result.token) {
                    return console.log('No token received');
                }

                const params = {
                    cartId: shoppingCartId,
                    amount: subTotal,
                    payment: 12,
                    token: result.token,
                    accountNumber:accountNumber,
                    routingNumber:routingNumber,
                    accountOwner: `${firstName} ${lastName}`,
                    accountType: accountType
                };

                addPayment(params);

                return true;
            });
        }
        catch (ex) {
            setPaymentError(true);
            setPaymentPending(false);
            const message = ex?.message || JSON.stringify(ex);
            setError(message);
            return false;
        }

    }

    useEffect(() => {
        if (!shoppingCartId) return;
        if (paymentError) return;

        const pollCart = async () => {
            const cart = await getCart(shoppingCartId);
    
            if (!cart.payments?.[0]) {
                return 'Pending';
            }
    
            if (['Failed','Canceled'].includes(cart.payments[0].paymentStatus)) {
                setPaymentError(true);
                setError(cart.payments[0].paymentStatus);
            }
    
            if (cart.payments[0].paymentStatus === 'Succeeded') {
                dispatch(userActions.setTransactionComplete(true));
                history('/complete', {replace: true});
            }
           
        }

        const pollInterval = setInterval(() => {
            if (paymentPending) {
                pollCart();
            }
        }, 5000);
        return () => clearInterval(pollInterval);
    },[paymentPending, shoppingCartId, dispatch, history, paymentError]);

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return (
        <Fragment>
            <Row>
                <Col md={7} style={{margin:"2rem auto"}}>
                    <h3>Pay with E-Check/ACH</h3>
                    <h4>Total Amount Due: {USDollar.format(cart.totalCharge)}</h4>
                </Col>
            </Row>
            <Row>
                <Col md={7} style={{margin:"2rem auto"}}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="firstName">
                                Account Holder First Name
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="text" 
                                    onBlur={event => setFirstName(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="lastName">
                                Account Holder Last Name
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="text" 
                                    onBlur={event => setLastName(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="routingNumber">
                                Routing Number
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="text" 
                                    onBlur={event => setRoutingNumber(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="accountNumber">
                                Account Number
                            </Form.Label>
                            <Col md={6}>
                                <Form.Control
                                    type="text" 
                                    onBlur={event => setAccountNumber(event.target.value)}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3">
                            <Form.Label column md={4} htmlFor="accountType">
                                Account Type
                            </Form.Label>
                            <Col md={6}>
                                <Form.Select
                                    type="select" 
                                    onChange={event => setAccountType(event.target.value)}
                                >
                                    <option value=""></option>
                                    <option value="checking">Checking</option>
                                    <option value="savings">Savings</option>
                                </Form.Select>
                            </Col>
                        </Form.Group>
                        {paymentError && (
                            <Row>
                                <Col md={10}>
                                <Error message={`Payment Processing Error. Please do not attempt another transaction. Send the following code to info@appd.org: ${error}`} />
                                </Col>
                            </Row>
                        )}
                        <Row className="mb-3">
                            <Col md={6}>
                                {paymentPending ? <p>Payment Processing...</p> :
                                    <Button type="submit">Submit Payment</Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row>
                <Col md={6} style={{margin: '0 auto'}}>
                    <Button className={'btn-secondary'} onClick={() => resetForm()}>Choose Another Payment Method</Button> 
                </Col>
            </Row>
        </Fragment>
    )
}

export default ACH;