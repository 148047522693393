import { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactDOMServer from 'react-dom/server';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import { getCart } from '../../lib/shoppingCartFunctions';
import { clientActions } from '../../store/client-slice';
import { sendEmail } from '../../lib/api';

const Complete = () => {
    const dispatch = useDispatch(false);
    const cart = useSelector(state => state.client.shoppingCart);
    const cartId = useSelector(state => state.client.shoppingCartId);
    const members = useSelector(state => state.client.members);
    const region = useSelector(state => state.client.region);
    const email = useSelector(state => state.user.email);
    const clientName = useSelector(state => state.client.clientName);
    const [payStatus, setPayStatus] = useState();
    const [paymentMethod, setPaymentMethod] = useState();
    const startDate = new Date(process.env.REACT_APP_TERM_START_DATE);
    const endDate = new Date(process.env.REACT_APP_TERM_END_DATE);

    useEffect(() => {
        let isSubscribed = true;

        const updateCart = async () => {
            const response = await getCart(cartId);

            if (!response) {
                return console.log('Cart update error');
            }

            dispatch(clientActions.setShoppingCart(response));
        }

        if (isSubscribed) {
            isSubscribed = false;
            updateCart();
        }

    },[cartId, dispatch]);

    useEffect(() => {
        if (!cart) {
            return console.log('No cart found');
        }

        if (!cart.payments.length) {
            setPaymentMethod('Check');
            setPayStatus('Invoice');
        }
        else {
            switch (cart.payments[0].tenderType) {
                case 4:
                    setPaymentMethod('Credit Card Online');
                    setPayStatus('Receipt');
                    break;
                case 12:
                    setPaymentMethod('E-Check/ACH');
                    setPayStatus('Receipt');
                    break;
                default:
                    setPaymentMethod('N/A');
                    setPayStatus('Invoice');
            }
        }
    },[cart]);

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const Cart = () => {
        return (
            <Fragment>
            <Table bordered hover width={800}>
                <thead>
                    <tr>
                        <th colSpan={3}><h2>This is your {payStatus}</h2></th>
                    </tr>
                    <tr>
                        <th colSpan={3}>
                            <img src='https://apps.appd.org/assets/appd-logo-type@2x.png' alt='APPD LOGO' width={200} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            Order #
                        </td>
                        <td colSpan={2}>
                            {cart.id}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Date
                        </td>
                        <td colSpan={2}>
                            {cart.orderDate}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Payment Method
                        </td>
                        <td colSpan={2}>
                            {paymentMethod}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            Program
                        </td>
                        <td colSpan={2}>
                        <strong>{clientName}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            Total Members
                        </td>
                        <td>
                        <strong>{members.length}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <strong>Total Amount {payStatus === 'Receipt' ? 'Paid' : 'Due'}</strong>
                        </td>
                        <td>
                        <strong>{USDollar.format(cart.totalCharge)}</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>Membership Fees</td>
                        <td>
                            {cart.memberships[0].membershipLevel.name}
                        </td>
                        <td>
                            {USDollar.format(cart.memberships[0].fee)}
                        </td>
                    </tr>
                    {cart.donations && (
                        <tr>
                            <td>
                                Region Dues
                            </td>
                            <td>
                                {region}
                            </td>
                            <td>
                            {USDollar.format(cart.donations[0].amount)}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <Table bordered hover width={800} style={{marginTop: '3rem'}}>
                <thead>
                    <tr>
                        <th>Program Member</th>
                        <th>
                            Job Role
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Additional Fee
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {members.map((member, idx) => {
                        return (
                            <tr key={`member-${idx}`}>
                                <td>{member['First Name']} {member['Last Name']}{member['Suffix'] && `, ${member['Suffix']}`}</td>
                                <td>{member['Job Title']} {member['Alternate Member Type']}</td>
                                <td>{member['Email 1']}</td>
                                <td>{member.covered ? '$0.00' : 
                                            `$${parseInt(process.env.REACT_APP_SUBMEMBER_FEE).toFixed(2)}`}</td>
                            </tr>
                        )
                    })}
                    <tr>
                        <td colSpan={3}>
                            <strong>Total Amount {payStatus === 'Receipt' ? 'Paid' : 'Due'}</strong>
                        </td>
                        <td>
                        <strong>{USDollar.format(cart.totalCharge)}</strong>
                        </td>
                    </tr>
                    {payStatus === 'Invoice' && (
                        <tr>
                            <td>
                                <h5>Remit Payment To:</h5>
                            </td>
                            <td colSpan={3}>
                                <div style={{textAlign: 'center'}}>
                                    <h5>APPD<br />
                                    6728 Old McLean Village Drive<br />
                                    Mclean, VA 22101</h5>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            </Fragment>
        )
    }
    
    useEffect(() => {
        if (!payStatus) {
            return
        }

        const emailParams = {
            sender: 'APPD National Office <info@appd.org>',
            recipient: email,
            bccEmail: ['daglyn@appd.org','tim@degnon.org','info@appd.org','degnonit@degnon.org'],
            subject: `APPD Membership Renewal ${payStatus}`,
            body_html: ReactDOMServer.renderToStaticMarkup(<Cart />)
        }

        const sendNotification = async () => {
            return await sendEmail(emailParams);
        }

        sendNotification();
    },[payStatus, email]);

    return (
        <Fragment>
            <Row>
                <Col sm={6} style={{margin:"2rem auto", textAlign:"center"}}>
                    <h3>Your APPD Membership renewal has been submitted</h3>
                    <p>You will receive an email confirmation shortly</p>
                    {payStatus === 'Invoice' && (
                        <Alert>Selecting Paying by Check means that the program's membership records will only be updated to current for {startDate.getFullYear()}-{endDate.getFullYear()} once payment is received and applied at the APPD National Office.</Alert>
                    )}
                </Col>
            </Row>

            <Cart />
        </Fragment>
    )
}

export default Complete;