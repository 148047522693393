import { verifyCookie } from './verify-cookies';
import jwt_decode from 'jwt-decode';
import { Buffer } from 'buffer';



export async function setJWT(params) {
  if (typeof params !== 'object') {
    return {error: true, message: 'Invalid JWT Request'};
  }
  
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}degnon/token`, {
      method: 'POST',
      headers: {
        
        'Content-Type':'application/json'
      },
      body: JSON.stringify(params)
    });

    if (!response.ok) {
      console.log('responsedata error');
      return {error: true, message: await response.text()};
    }

    return await response.json();
  }
  catch (ex) {
    return {error: true, message: ex};
  }
}

export async function validateOauthToken(code) {
  if (!code) return {};
    
  const authCookie = verifyCookie('auth_code');
  if (!authCookie) {return {};}

  const jwToken = await jwt_decode(authCookie);

  const response = await fetch(`https://secret-atoll-79806.herokuapp.com/https://app.neoncrm.com/np/oauth/token`, {
    method: 'POST',
    headers:{
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body:  new URLSearchParams({
      'client_id': jwToken.neonOauthId,
      'client_secret': jwToken.neonOauthSecret,
      'redirect_uri':`${process.env.REACT_APP_URL}`,
      'grant_type': 'authorization_code',
      'code': code
    })
  });

  const responseData = await response.json();
  return responseData;
}

export async function getCustomFields() {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}customFields?category=Account`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      }
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
    return responseData;
  }
  catch (ex) {
    return null;
  }
  
}

export async function getMembershipTerms() {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}/memberships/terms`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      }
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
    return responseData;
  }
  catch (ex) {
    return null;
  }
  
}

export async function getMembershipStatus(accountId) {
  let membershipIsCurrent = false;
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/accounts/${accountId}/memberships`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      }
    });
    if (!response.ok) {
      return null;
    }
    const membershipsData = await response.json();
  
    membershipsData.memberships.map((membership) => {
      if (membership.termEndDate >= process.env.REACT_APP_TERM_END_DATE && ['SUCCEEDED'].includes(membership.status)) {
        return membershipIsCurrent = true;
      }
      
      return null;
    });
  
    return membershipIsCurrent;
  }
  catch (ex) {
    return null;
  }
}

export async function getProgramInformation(accountId) {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/accounts/${accountId}`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      }
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
  
    if(!responseData.companyAccount) {
      return null;
    }
    return responseData.companyAccount;
  }
  catch (ex) {
    return null;
  }
}

export async function getIndividualAccount(accountId) {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/accounts/${accountId}`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      }
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
  
    if(!responseData.individualAccount) {
      return null;
    }
    return responseData.individualAccount;
  }
  catch (ex) {
    return null;
  }
}

export async function updateIndividualAccount(data) {
  const {accountId, body, companyAccountId = 0, isPrimaryContact} = data;
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}accounts/${accountId}`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken,
        'NEON-API-VERSION': '2.8'
      },
      method: 'PATCH',
      body: JSON.stringify(body)
    });

    if (!response.ok) {
      const error = {error: true, message: await response.text()};
      return error;
    }

    const responseData = await response.json();
  
    if (isPrimaryContact === 'Yes') {
      const companyContacts = await getCompanyContacts(companyAccountId);

      const memberContact = await companyContacts.filter(contact => contact.accountId === accountId);
      const contactId = await memberContact[0].contactId;
      await setPrimaryContact(companyAccountId, contactId);
    }
  
    return responseData;
  }
  catch (ex) {
    return {error: true, message: ex};
  }
}

export async function addIndividualAccount(data, companyAccountId, isPrimaryContact) {
  const searchData = [
      {
          field: 'Email',
          type: 'text',
          value: data.individualAccount.primaryContact.email1
      },
      {
          field: 'Last Name',
          type: 'text',
          value: data.individualAccount.primaryContact.lastName
      }
  ];

  try {
    const findAccount = await searchAccounts(searchData);

    if (findAccount) {

      const accountId = findAccount[0]['Account ID'];
      const userAccount = await getIndividualAccount(accountId);

      if (userAccount?.primaryContact?.addresses?.[0]) {
        data.individualAccount.primaryContact.addresses[0].addressId = userAccount.primaryContact.addresses[0].addressId;
      }
      
      let updateUser = await updateIndividualAccount({accountId : accountId, body:data});

      if (updateUser.error) {
        const error = JSON.parse(updateUser.message);
        const errorCode = parseInt(error?.[0]?.code) || 0;

        if (errorCode !== 10026) {
          console.log(`updateUser error not 10026:${errorCode}`);
          return updateUser;
        }

        let pc = false;
        if (isPrimaryContact === 'Yes') {
          pc = true;
        }
        
        const newContactData = {
          individualContactId: userAccount.primaryContact.contactId,
          companyAccountId: companyAccountId,
          isCurrentEmployee: true,
          isPrimaryContact: pc,
          title: data.individualAccount.primaryContact.title
        }

        const newLinkedContact = await linkNewCompanyContact(newContactData);

        if (newLinkedContact?.error) {
          return newLinkedContact;
        }

        updateUser = await updateIndividualAccount({accountId : accountId, body:data});

        if (updateUser.error) {
          console.log(`updateUser error after adding contact:${errorCode}`);
          return updateUser;
        }
      }

      const companyContacts = await getCompanyContacts(companyAccountId);

      const memberContact = await companyContacts.filter(contact => contact.accountId === accountId);
      const contactId = await memberContact[0]?.contactId;
      
      if (isPrimaryContact === 'Yes') {
        await setPrimaryContact(companyAccountId, contactId);
      }

      return {
        code: 'duplicate',
        message: 'Existing Account Has Been Updated',
        data: updateUser
      };
    }
    else {
      const addUser = await createIndividualAccount(data, isPrimaryContact, companyAccountId);

      if (addUser?.error) {
        return {code: 'error', message: addUser.message};
      }

      return {
        code: 'success',
        message: 'Account added',
        data: addUser
      };
    }
  }
  catch (ex) {
    return {
      code: 'error',
      message: ex
    };
  }
}

const linkNewCompanyContact = async (data) => {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}accounts/link`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken,
        'NEON-API-VERSION': '2.8'
      },
      method: 'POST',
      body: JSON.stringify(data)
    });

    if (!response.ok) {
      const error = {error: true, message: await response.text()};
      console.log('RESPONSE ERROR!', error);
      return error;
    }

    return await response.json();
  }
  catch (ex) {
    return {error: true, message: ex};
  }
}

export async function updateProgramInformation(accountId, formData) {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/accounts/company/${accountId}`, {
      method: 'PATCH',
        headers: {
            'Authorization': authToken,
            'content-type': 'application/json',
            'NEON-API-VERSION': '2.8'
        },
        body: JSON.stringify(formData)
    });
    if (!response.ok) {
      return {error: true, message: await response.text()};
    }
    
    return await response.json();  
  }
  catch (ex) {
    return {error: true, message: ex};
  }
};

export async function setAccountCustomFields(formData) {
  const client = process.env.REACT_APP_NEON_API_USER;

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/customFields/${client}/parse`, {
      method: 'POST',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify(formData)
    });
    if (!response.ok) {
      return null;
    }
  
    const result = await response.json();
    
  
    const cleanResult = result.filter(field => field.component !== 'Membership');
  
    cleanResult.map((item) => {
      return delete item.component;
    })
    
    return cleanResult; 
  } 
  catch (ex) {
    return null;
  }
};

export async function getProgramMembers(accountId, memberRoles) {
  
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const programData = await getProgramInformation(accountId);
    const companyPrimaryContact = programData.primaryContactAccountId;

    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/search/allAccounts`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'POST',
      body: JSON.stringify({
          searchFields:[
              {
                  field:"Company ID",
                  type:"text",
                  value:accountId
              },
              {
                field:"Job Title",
                type:"notblank"
              }
          ],
          outputFields:["Salutation","Membership Name","Department","Company ID",392,85,172,138,410],
          allUsers: true
      })
    });

    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
  
    if(!responseData) {
      return null;
    }
    if (responseData.length === 0) {
      return null;
    }
  
    const filteredMembers = responseData.filter(account => memberRoles.find(role => role === account['Job Title']));

    filteredMembers.forEach(item => {
      item.isPrimaryContact = 'No';
      if (item['Account ID'] === companyPrimaryContact) {
        item.isPrimaryContact = 'Yes';
      };
    });
  
    return filteredMembers.sort((a,b) => memberRoles.indexOf(a['Job Title']) - memberRoles.indexOf(b['Job Title']));
  }
  catch (ex) {
    return null;
  }
}

export async function removeProgramMember(params) {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');
  const {companyAccountId, firstName, lastName} = params;

  try {
    const companyContacts = await getCompanyContacts(companyAccountId);

    const contact = await companyContacts.find(o => o.firstName === firstName && o.lastName === lastName && o.currentEmployer);

    if(!contact) {
      return null;
    }

    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}accounts/${companyAccountId}/contacts/${contact.contactId}`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'PATCH',
      body: JSON.stringify({
          currentEmployer: false
      })
    });
    if (!response.ok) {
      return null;
    }

    return await response.json();
  }
  catch (ex) {
    return null;
  }
  

}

export async function findProgramMembers(params) {
  const { accountId, lastName } = params;
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/search`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'POST',
      body: JSON.stringify({
          searchFields:[
              {
                  field:"Company ID",
                  type:"text",
                  value:accountId
              },
              {
                  field:"Most Recent Membership Only",
                  type:"text",
                  value:"Yes"
              },
              {
                  field:"Last Name",
                  type:"contain",
                  value:lastName
              }
          ],
          outputFields:["Company ID",392,85,172,138,410],
          allUsers: true
  
      })
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
  
    if(!responseData.searchResults) {
      return null;
    }
    if (responseData.searchResults.length === 0) {
      return null;
    }
    return responseData.searchResults;
  }
  catch (ex) {
    return null;
  }
  
}

export async function getOtherSurveyMember(accountId) {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/search`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'POST',
      body: JSON.stringify({
          searchFields:[
              {
                  field:"Company ID",
                  type:"text",
                  value:accountId
              },
              {
                  field:103,
                  type:"text",
                  value:355
              }
          ],
          outputFields:["Company ID", 103]
  
      })
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
  
    if(!responseData.searchResults) {
      return null;
    }
    if (responseData.searchResults.length === 0) {
      return null;
    }
    return responseData.searchResults[0];
  }
  catch (ex) {
    return null;
  }
}

export async function getAPAJournalMember(accountId) {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/search`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'POST',
      body: JSON.stringify({
          searchFields:[
              {
                  field:"Company ID",
                  type:"text",
                  value:accountId
              },
              {
                  field:78,
                  type:"text",
                  value:2
              }
          ],
          outputFields:["Company ID", 78]
      })
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
  
    if(!responseData.searchResults) {
      return null;
    }
    if (responseData.searchResults.length === 0) {
      return null;
    }
    return responseData.searchResults[0];
  }
  catch (ex) {
    return null;
  }
}

export async function getCurriculumSurveyMember(accountId) {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/search`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'POST',
      body: JSON.stringify({
          searchFields:[
              {
                  field:"Company ID",
                  type:"text",
                  value:accountId
              },
              {
                  field:102,
                  type:"text",
                  value:357
              }
          ],
          outputFields:["Company ID", 102]
      })
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
  
    if(!responseData.searchResults) {
      return null;
    }
    if (responseData.searchResults.length === 0) {
      return null;
    }
    return responseData.searchResults[0];
  }
  catch (ex) {
    return null;
  }
}

export async function sendEmail(params) {
  const { sender, recipient, subject, body_html, bccEmail } = params;

  try {
    const response = await fetch(`https://degnon-api.herokuapp.com/mail`, {
      method: 'POST',
      headers: {
          'Content-Type':'application/json'
      },
      body: JSON.stringify({
          sender: sender,
          recipient: recipient,
          ...(bccEmail && {bccEmail: bccEmail}),
          subject: subject,
          body_html: body_html
      })
  });

  const responseData = await response;

  return responseData;
  }
  catch (ex) {
    return null;
  }
  
}

export async function createCompanyContact(params) {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  const { companyAccountId, accountId, primaryContact, title, firstName, lastName, suffix } = params;

  try {
    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}accounts/${companyAccountId}/contacts`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'POST',
      body: JSON.stringify({
        accountId: accountId,
        currentEmployer: true,
        title: title,
        primaryContact: primaryContact,
        firstName: firstName,
        lastName: lastName,
        suffix: suffix
      })
    });
    if (!response.ok) {
      return null;
    }
    
    return await response.json();
  }
  catch (ex) {
    return null;
  }
}

export async function getStateProvinces() {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}properties/stateProvinces`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      }
    });
  
    if (!response.ok) {
      return null;
    }
    
    const spResponse = await response.json();
    return spResponse;
  }
  catch (ex) {
    return null;
  }
}

export async function validateCompletion(companyAccountId, memberRoles) {
  try {
    const programMembers = await getProgramMembers(companyAccountId, memberRoles);
  const programInformation = await getProgramInformation(companyAccountId);
  let val = true;
  let valMsg = [];
  let programErrors = [];
  const requiredContactFields = ['First Name', 'Last Name', 'Email 1', 'Address Line 1', 'Address Line 2', 'City', 'Phone 1 Full Number (F)', 'State/Province', 'Zip Code'];
  const requiredProgramFields = ['PGY1 Residents', 'PGY2 Residents', 'PGY3 Residents', 'Program Setting', 'Categorical Residents', 'Combined Subspecialty Residents', 'Combined Resident Types', 'LEARN Participation'];

  programMembers.map(member => {
      const memberName = `${member["First Name"]} ${member["Last Name"]}`;
      let memberVal = [];

      requiredContactFields.map((field) => {
          if (!member[field] || member[field].length === 0) {
            val = false;
            const cleanText = field.replace('Phone 1 Full Number (F)', 'Phone')
                             .replace('Address Line 1', 'Organization')
                             .replace('Address Line 2', 'Address')
                             .replace('Email 1', 'Email');
            return memberVal.push(`${cleanText} is blank`)
          }
          return null
      });

      if (memberVal.length) {
        return valMsg.push({name: memberName, message: memberVal})
      }

      return null
    });

    requiredProgramFields.map((requiredField) => {
      let valueFound = programInformation.accountCustomFields.filter((customField) => customField.name === requiredField);

      if (!valueFound) {
        return programErrors.push(`${requiredField} requires a selection`)
      }

      return null
    });

    if (programErrors.length) {
      valMsg.push({name: 'Program Information', message: programErrors})
    }

    const valResponse = {
      status: val,
      message: valMsg
    }

    return valResponse;
  }
  catch (ex) {
    return {
      status: 'Error',
      message: ex
    };
  }
  
}

const getCompanyContacts = async (accountId) => {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}accounts/${accountId}/contacts`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      }
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
  
    if(!responseData.contacts) {
      return null;
    }
    if (responseData.contacts.length === 0) {
      return null;
    }
  
    let companyContacts = responseData.contacts;
  
    if(responseData.pagination.totalPages > 1) {
  
      for (let i=1; i < responseData.pagination.totalPages; i++) {
        const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}accounts/${accountId}/contacts?currentPage=${i}`, {
          headers: {
            'Content-Type':'application/json',
            'Authorization': authToken
          }
        });
      
        if (!response.ok) {
          return null;
        }
        const responseBody = await response.json();
  
        responseBody.contacts.map((contact) => {
          return companyContacts.push(contact);
        });
      }
    }
  
    return companyContacts;
  }
  catch (ex) {
    return null;
  }
}

const searchAccounts = async (searchData) => {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URI}neon/search`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'POST',
      body: JSON.stringify({
          searchFields:searchData,
          allUsers: true,
          outputFields:["Company ID"]
      })
    });
    if (!response.ok) {
      return null;
    }
    const responseData = await response.json();
  
    if(!responseData.searchResults) {
      return null;
    }
    if (responseData.searchResults.length === 0) {
      return null;
    }
    return responseData.searchResults;
  }
  catch (ex) {
    return null;
  }
}

const setPrimaryContact = async (accountId, contactId)  => {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}accounts/${accountId}/contacts/${contactId}`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'PATCH',
      body: JSON.stringify({
          primaryContact:true
      })
    });
    if (!response.ok) {
      return null;
    }
    
    return true;
  }
  catch (ex) {
    return null;
  }
}

const createIndividualAccount = async (data, isPrimaryContact, companyAccountId) => {
  const apiKey = process.env.REACT_APP_NEON_API_KEY;
  const client = process.env.REACT_APP_NEON_API_USER;
  const authToken = 'Basic ' + new Buffer.from(client + ':' + apiKey).toString('base64');

  try {
    const response = await fetch(`${process.env.REACT_APP_NEON_BASE_URI}accounts`, {
      headers: {
        'Content-Type':'application/json',
        'Authorization': authToken
      },
      method: 'POST',
      body: JSON.stringify(data)
    });
    if (!response.ok) {
      const error = {error: true, message: await response.text()};
      return error;
    }
    
    const addIndividualResponse = await response.json();

    if (isPrimaryContact === 'Yes') {
      const newUser = await getIndividualAccount(addIndividualResponse.id);
      await setPrimaryContact(companyAccountId, newUser.primaryContact.contactId);
    }

    return addIndividualResponse.id;
  }
  catch (ex) {
    return {error: true, message: ex};
  }
}