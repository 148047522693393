import { Fragment } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import OtherSurveyMember from './OtherSurveyMember';
import APAJournalMember from './APAJournalMember';
import CurriculumSurveyMember from './CurriculumSurveyMember';

const ProgramContacts = () => {
    return (
        <Fragment>
            <Row>
                <Col md={9} style={{margin: '0 auto'}}>
                    <h2>Program Contacts</h2>
                </Col>
            </Row>

            <Row>
                <Col md={4}>
                    <APAJournalMember />
                </Col>
                <Col md={4}>
                    <CurriculumSurveyMember />
                </Col>
                <Col md={4}>
                    <OtherSurveyMember /> 
                </Col>
            </Row>
        </Fragment>    
    )
}

export default ProgramContacts;