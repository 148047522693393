import { useEffect, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { verifyCookie } from '../../lib/verify-cookies';
import jwt_decode from 'jwt-decode';
import { userActions } from '../../store/user-slice';
import { useState } from 'react';
import { clientActions } from '../../store/client-slice';
import useHttp from '../../hooks/use-http';
import { validateOauthToken, getIndividualAccount } from '../../lib/api';
import Loading from '../UI/Loading';
import Error from '../UI/Error';

const Authenticate = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const code = params.get('code');
  const errorDescription = params.get('error_description');
  const authCookie = verifyCookie('auth_code');
  const [error, setError] = useState();
  const [loading, setLoading] = useState();

  const { sendRequest, status, data: oauthResponse } = useHttp(validateOauthToken);

  useEffect(() => {
    sendRequest(code);
  }, [code, sendRequest]);


  useEffect(() => {
    if (errorDescription) {
      setError(errorDescription);
      return;
    }

    const getToken = async() => {
      return await jwt_decode(authCookie);
    };

    let isSubscribed = true;

    if(!code){
      getToken().then((jwToken) => {
        window.location = `https://${jwToken.neonClientName}.app.neoncrm.com/np/oauth/auth?response_type=code&client_id=${encodeURIComponent(jwToken.neonOauthId)}&redirect_uri=${process.env.REACT_APP_URL_DOMAIN}/authenticate`;  
      }).catch(ex => {
        
        console.log(ex, authCookie);
      });
    }

    const updateOauthState = async (data) => {
      dispatch(userActions.setIsLoggedIn(data));
      const jwToken = await getToken();    
      const storeData = {            
        apiKey: jwToken.apiKey,
        neonClientName: jwToken.neonClientName,
        abbreviation: jwToken.abbreviation,
        neonAdminToken: jwToken.neonAdminToken,
        neonOauthId: jwToken.neonOauthId,
        neonOauthSecret: jwToken.neonOauthSecret
      };
    
      dispatch (clientActions.setNeonApiKey(storeData));
    };

    const getCompanyAccountId = async (individualAccountId) => {
      const individualAccount = await getIndividualAccount(individualAccountId);

      if (!individualAccount?.company.id) {
        setError('Unauthorized User');
        return false;
      }

      dispatch(clientActions.setClientName(individualAccount.company.name));
      return individualAccount.company.id;
    }

    if(status === 'completed' && oauthResponse && oauthResponse.access_token && isSubscribed){
        setError(false);
        setLoading(true);

        getCompanyAccountId(oauthResponse.access_token).then((companyAccountId) => {
          if (companyAccountId) {
            isSubscribed = false;
            document.cookie = `neon_access_token=${oauthResponse.access_token};max-age=259200;domain${process.env.REACT_APP_DOMAIN}`;
            updateOauthState(oauthResponse.access_token);
            dispatch(clientActions.setNeonAccountId(companyAccountId));
            history('/', {replace: true});
          }
          else {
            setError(true);
            setLoading(false);
          }
        });

    }

  }, [history, oauthResponse, status, dispatch, authCookie, code, errorDescription]);
        
  return (
    <Fragment>
      {error && <Error message={error} />}
      {loading && <Loading />}
    </Fragment>
  );
};


export default Authenticate;
