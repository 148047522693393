import { createSlice } from '@reduxjs/toolkit';

const membershipCustomFieldsSlice = createSlice({
    name: 'membershipCustomFields',
    initialState: {jobRole: {
        id: 392,
        values: [
            {
                "id": "1407",
                "name": "Program Director"
            },
            {
                "id": "1408",
                "name": "Department Chair"
            },
            {
                "id": "1409",
                "name": "Residency Co-Director"
            },
            {
                "id": "1410",
                "name": "Pediatric Residency Program Coordinator"
            },
            {
                "id": "1411",
                "name": "Associate Residency Program Director"
            },
            {
                "id": "1412",
                "name": "Chief Resident"
            },
            {
                "id": "1413",
                "name": "Med-Peds Program Director"
            },
            {
                "id": "1414",
                "name": "Subspecialty Program Director"
            },
            {
                "id": "1415",
                "name": "Subspecialty Program Coordinator"
            },
            {
                "id": "1416",
                "name": "Program Coordinator-Other"
            },
            {
                "id": "1417",
                "name": "Super Program Director"
            },
            {
                "id": "1418",
                "name": "Designated Institutional Official"
            },
            {
                "id": "1419",
                "name": "Rotation Director"
            },
            {
                "id": "1420",
                "name": "Other"
            },
            {
                "id": "1421",
                "name": "Global Health Educator"
            },
            {
                "id": "1422",
                "name": "QI Specialist"
            },
            {
                "id": "1423",
                "name": "Vice Chair of Education"
            },
            {
                "id": "1424",
                "name": "Associate Fellowship Program Director"
            },
            {
                "id": "1425",
                "name": "Fellowship Co-Director"
            },
            {
                "id": "1426",
                "name": "Primary Care/Continuity Clinic Director"
            },
            {
                "id": "1427",
                "name": "Rising Chief Resident"
            },
            {
                "id": "1428",
                "name": "Graduating Chief Resident"
            },
            {
                "id": "1429",
                "name": "Associate Vice Chair of Education"
            },
            {
                "id": "1461",
                "name": "Associate Dean"
            },
            {
                "id": "1462",
                "name": "Dean"
            },
            {
                "id": "1463",
                "name": "Program Manager"
            },
            {
                "id": "1464",
                "name": "Resident (PGY-1)"
            },
            {
                "id": "1465",
                "name": "Resident (PGY-2)"
            },
            {
                "id": "1466",
                "name": "Resident (PGY-3)"
            },
            {
                "id": "1467",
                "name": "Resident (PGY-4)"
            },
            {
                "id": "1484",
                "name": "Rising Chief Resident"
            }
        ],
    }},
    reducers: {
        setJobRole(state, action) {
            state.jobRole = action.payload;
        },
        clearState(state, action) {
            if (action === 'CLEAR_SESSION') {
                state = undefined;
            }
        },
    },
});

export const membershipCustomFieldsActions = membershipCustomFieldsSlice.actions;

export default membershipCustomFieldsSlice.reducer;