import { createSlice } from '@reduxjs/toolkit';

const membershipTermsSlice = createSlice({
  name: 'membershipTerms',
  initialState: {terms: [
    {
      level: "Tier 1",
      id: 104,
      fee: 2960,
      coveredTermId: 1146,
      paidTermId: 1148
    },
    {
      level: "Tier 2",
      id: 106,
      fee: 7620,
      coveredTermId: 1140,
      paidTermId: 0
    },
    {
      level: "Tier 3",
      id: 108,
      fee: 9030,
      coveredTermId: 1152,
      paidTermId: 0
    },
    {
      level: "Tier 4",
      id: 110,
      fee: 10470,
      coveredTermId: 1156,
      paidTermId: 0
    },
    {
      level: "Tier 5",
      id: 1000,
      fee: 11245,
      coveredTermId: 1160,
      paidTermId: 0
    }
  ]},
  reducers: {
    setTerms(state, action) {
      state.terms = action.payload;
    },
    clearState(state, action) {
      if (action === 'CLEAR_SESSION') {
        state = undefined;
      }
    },
  },
});

export const membershipTermsActions = membershipTermsSlice.actions; 

export default membershipTermsSlice.reducer;