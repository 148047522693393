import { createSlice } from '@reduxjs/toolkit';

const clientSlice = createSlice({
  name: 'clientData',
  initialState: {
    clientInfo: {},
    clientName: null, 
    clientAccountId: null,
    memberRoles: ['Program Director', 'Department Chair', 'Residency Co-Director', 'Pediatric Residency Program Coordinator', 'Associate Residency Program Director', 'Med-Peds Program Director', 'Subspecialty Program Director', 'Subspecialty Program Coordinator', 'Program Coordinator-Other', '"Super" Program Director', 'Designated Institutional Official', 'Rotation Director', 'Global Health Educator', 'QI Specialist', 'Vice Chair of Education', 'Associate Fellowship Program Dir', 'Fellowship Co-Director', 'Primary Care/Continuity Clinic Director', 'Associate Vice Chair of Education', 'Advocacy Educator', 'Other', 'Associate Dean', 'Dean', 'Chief Resident', 'Rising Chief Resident', 'Resident (PGY-4)', 'Resident (PGY-3)', 'Resident (PGY-2)', 'Resident (PGY-1)'],
    members:null,
    shoppingCartId: null,
    shoppingCart:null,
    shoppingCartStale:false,
    region:null
  },
  reducers: {
    setNeonApiKey(state, action) {
      state.clientInfo = {
        ...action.payload.abbreviation && { name: action.payload.abbreviation },
        ...action.payload.neonAdminToken && { adminToken: action.payload.neonAdminToken },
        ...action.payload.apiKey && { apiKey: action.payload.apiKey },
        ...action.payload.neonClientName && { neonClientName: action.payload.neonClientName },
        ...action.payload.neonOauthId && { neonOauthId: action.payload.neonOauthId },
        ...action.payload.neonOauthSecret && { neonOauthSecret: action.payload.neonOauthSecret },
      };
    },
    setNeonAccountId(state, action) {
      state.clientAccountId = action.payload
    },
    setShoppingCartId(state, action) {
      state.shoppingCartId = action.payload
    },
    setShoppingCart(state, action) {
      state.shoppingCart = action.payload
    },
    setClientName(state, action) {
      state.clientName = action.payload
    },
    setRegion(state, action) {
      state.region = action.payload
    },
    setMembers(state, action) {
      state.members = action.payload
    },
    setShoppingCartStale(state, action) {
      state.shoppingCartStale = action.payload
    },
    clearState(state, action) {
      if (action === 'CLEAR_SESSION') {
        state = undefined;
      }
    },
  },
});

export const clientActions = clientSlice.actions; 

export default clientSlice.reducer;