import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import * as Yup from 'yup';

const MemberForm = (props) => {
    const [isSubscribed, setIsSubscribed] = useState(true);
    const [hasSubspecialty, setHasSubspecialty] = useState();
    const [hasAlternateSubspecialty, setHasAlternateSubspecialty] = useState();
    const [subspecialtyList, setSubspecialtyList] = useState(["Subspecialty Program Director","Subspecialty Program Coordinator","Program Coordinator-Other","Super Program Director","Rotation Director","Associate Fellowship Program Director","Fellowship Co-Director"]);
    const [sortedMemberRoles, setSortedMemberRoles] = useState();
    const stateProvinces = useSelector(state => state.stateProvinces.values);
    const subspecialties = useSelector(state => state.subspecialties.subspecialties);
    const memberRoles = useSelector(state => state.client.memberRoles);

    useEffect(() => {
        if (sortedMemberRoles) return;
        const roles = [...memberRoles]; 
        setSortedMemberRoles(roles.sort());
    },[memberRoles, sortedMemberRoles]);

    useEffect(() => {
        if (subspecialtyList.includes(props.membershipType)) {
            setHasSubspecialty(true);
        }

        if (props.member) { 
            if (subspecialtyList.includes(props.member['Job Title'])) {
                setHasSubspecialty(true);
            }

            if (props.member['Alternate Member Type'] && subspecialtyList.filter(item => item === props.member['Alternate Member Type']).length) {
                setHasAlternateSubspecialty(true);
            }
        }
        
        setIsSubscribed(false);
    },[props.member, props.membershipType, subspecialtyList]);

    const handleAlternateMemberType = (event) => {
        if (subspecialtyList.includes(event.target.value)) {
            setHasAlternateSubspecialty(true);
        }
        else {
            setHasAlternateSubspecialty(false);
        }
    }

    const handleTitleChange = (event) => {
        if (subspecialtyList.includes(event.target.value)) {
            setHasSubspecialty(true);
        }
        else {
            setHasSubspecialty(false);
        }
    }
    
    const formik = useFormik({
        initialValues: {
            salutation: props.member?.["Salutation"] ? props.member["Salutation"] : '',
            firstName: props.member?.["First Name"] ? props.member["First Name"] : '',
            lastName: props.member?.["Last Name"] ? props.member["Last Name"] : '',
            degree: props.member?.["Suffix"] ? props.member["Suffix"] : '',
            jobTitle: props.member?.["Job Title"] ? props.member["Job Title"] : props.membershipType,
            title: props.member?.['Title'] ? props.member['Title'] : '',
            addressLine1: props.member?.["Address Line 1"] ? props.member["Address Line 1"] : '',
            addressLine2: props.member?.["Address Line 2"] ? props.member["Address Line 2"] : '',
            city: props.member?.["City"] ? props.member["City"] : '',
            stateProvince: props.member?.["State/Province"] ? props.member["State/Province"] : '',
            zipCode: props.member?.["Zip Code"] ? props.member["Zip Code"] : '',
            phone: props.member?.["Phone 1 Full Number (F)"] ? props.member["Phone 1 Full Number (F)"] : '',
            email: props.member?.["Email 1"] ? props.member["Email 1"] : '',
            subSpecialty:  props.member?.['Sub-Specialty'] ? props.member['Sub-Specialty'] : '',
            alternateMemberType: props.member?.['Alternate Member Type'] ? props.member['Alternate Member Type'] : '',
            alternateSubspecialty: props.member?.['Other Job Specialty'] ? props.member['Other Job Specialty'] : '',
            isPrimaryContact: props.member?.isPrimaryContact ? props.member.isPrimaryContact : false
        },
        validationSchema: Yup.object().shape({
            firstName: Yup.string()
                .max(25, 'Must be 25 characters or less')
                .required('Required'),
            lastName: Yup.string()
                .max(25, 'Must be 25 characters or less')
                .required('Required'),
            email: Yup.string().email('Invalid email address').required('Required'),
            jobTitle: Yup.string()
                .oneOf(memberRoles)
                .required('Required'),
            title: Yup.string(),
            subSpecialty: Yup.string()
                .when('jobTitle', {
                    is: (value) => subspecialtyList.includes(value),
                    then: () => Yup.string().required('Subspecialty is required'),
                    otherwise: () => Yup.string()
                }),
            addressLine1: Yup.string()
                .max(64, 'Must be 64 characters or less')
                .required('Required'),
            addressLine2: Yup.string()
                .max(64, 'Must be 64 characters or less')
                .required('Required'),
            city: Yup.string()
                .max(50, 'Must be 50 characters or less')
                .required('Required'),
            stateProvince: Yup.string()
                .required('Required'),
            zipCode: Yup.string()
                .max(25, 'Must be 25 characters or less')
                .required('Required'),
            phone: Yup.string()
                .required('Required'),
            alternateMemberType: Yup.string()
            .oneOf(memberRoles),
            alternateSubspecialty: Yup.string()
                .when('alternateMemberType', {
                    is: (value) => subspecialtyList.includes(value),
                    then: () => Yup.string().required('Alternate Subspecialty is required'),
                    otherwise: () => Yup.string()
                }),
            isPrimaryContact: Yup.string()
                .required('Required')
        }),
        onSubmit: values => {          
            props.submitForm(values);
        },
    });

    return (
        <div>
            {isSubscribed ? <p>Loading</p> : (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}htmlFor="zipCode">This Member is the Primary Contact for your Institution</Form.Label>
                <Col sm="6">
                    <Form.Select
                        name="isPrimaryContact"
                        type="isPrimaryContact" 
                        onChange={event => {
                            formik.handleChange(event);
                        }}
                        onBlur={formik.handleBlur}
                        value={formik.values.isPrimaryContact}
                    >
                        <option value=""></option>
                        <option value={'Yes'}>Yes</option>
                        <option value={'No'}>No</option>
                    </Form.Select>
                    {formik.touched.isPrimaryContact && formik.errors.isPrimaryContact ? (
                        <div style={{color:'red'}}>{formik.errors.isPrimaryContact}</div>
                    ) : null}
                </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="salutation">Salutation</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="salutation"
                            name="salutation"
                            type="text" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.salutation} 
                        />
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="firstName">First Name</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="firstName"
                            name="firstName"
                            type="text" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName} 
                        />
                        {formik.touched.firstName && formik.errors.firstName ? (
                            <div style={{color:'red'}}>{formik.errors.firstName}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="lastName">Last Name</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="lastName"
                            name="lastName"
                            type="text" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}  
                        />
                        {formik.touched.lastName && formik.errors.lastName ? (
                            <div style={{color:'red'}}>{formik.errors.lastName}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="degree">Degree</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="degree"
                            name="degree"
                            type="text" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.degree} 
                        />
                        {formik.touched.degree && formik.errors.degree ? (
                            <div style={{color:'red'}}>{formik.errors.degree}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="jobTitle">Job Role (Member Type)</Form.Label>
                    <Col sm={6}>
                        <Form.Select
                            id="jobTitle"
                            name="jobTitle"
                            onChange={event => {
                                handleTitleChange(event);
                                formik.handleChange(event);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.jobTitle}
                        >
                            <option value=""></option>
                            {sortedMemberRoles.map((r, index) => (
                                <option 
                                    key={`jobTitle-${index}`} 
                                    value={r}
                                >
                                    {r}
                                </option>
                            ))}
                        </Form.Select>
                        {formik.touched.jobTitle && formik.errors.jobTitle ? (
                            <div style={{color:'red'}}>{formik.errors.jobTitle}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="title">Title</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="title"
                            name="title"
                            type="text" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.title} 
                        />
                        {formik.touched.title && formik.errors.title ? (
                            <div style={{color:'red'}}>{formik.errors.title}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                {hasSubspecialty && (
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}htmlFor="subSpecialty">Subspecialty</Form.Label>
                        <Col sm={6}>
                            <Form.Select
                                id="subSpecialty"
                                name="subSpecialty"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.subSpecialty}
                            >
                                <option value=""></option>
                                {subspecialties.map((s, index) => (
                                    <option 
                                        key={`subspecialty-${index}`} 
                                        value={s.name}
                                    >
                                        {s.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {formik.touched.subspecialty && formik.errors.subspecialty ? (
                            <div style={{color:'red'}}>{formik.errors.subspecialty}</div>
                        ) : null}
                        </Col>
                    </Form.Group>
                )}
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="addressLine1">Organization</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="addressLine1"
                            name="addressLine1"
                            type="text" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.addressLine1} 
                        />
                        {formik.touched.addressLine1 && formik.errors.addressLine1 ? (
                            <div style={{color:'red'}}>{formik.errors.addressLine1}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="addressLine2">Address</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="addressLine2"
                            name="addressLine2"
                            type="text" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.addressLine2} 
                        />
                        {formik.touched.addressLine2 && formik.errors.addressLine2 ? (
                            <div style={{color:'red'}}>{formik.errors.addressLine2}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="city">City</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="city"
                            name="city"
                            type="text" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city} 
                        />
                        {formik.touched.city && formik.errors.city ? (
                            <div style={{color:'red'}}>{formik.errors.city}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="stateProvince">State/Province</Form.Label>
                    <Col sm={6}>
                        <Form.Select
                                id="stateProvince"
                                name="stateProvince"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.stateProvince}
                            >
                                <option value=""></option>
                                {stateProvinces.length && stateProvinces.map((sp, index) => (
                                    <option 
                                        key={`stateProvince-${index}`} 
                                        value={sp.code}
                                    >
                                        {sp.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {formik.touched.stateProvince && formik.errors.stateProvince ? (
                            <div style={{color:'red'}}>{formik.errors.stateProvince}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="zipCode">Zip/Postal Code</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="zipCode"
                            name="zipCode"
                            type="text" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.zipCode} 
                        />
                        {formik.touched.zipCode && formik.errors.zipCode ? (
                            <div style={{color:'red'}}>{formik.errors.zipCode}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="phone">Phone</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="phone"
                            name="phone"
                            type="phone" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phone} 
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                            <div style={{color:'red'}}>{formik.errors.phone}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                    <Form.Label column sm={4}htmlFor="email">Email</Form.Label>
                    <Col sm={6}>
                        <Form.Control 
                            id="email"
                            name="email"
                            type="email" 
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email} 
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div style={{color:'red'}}>{formik.errors.email}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                <Row>
                    <Col>
                        <h4>Other Role</h4>
                    </Col>
                </Row>
                <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={4}htmlFor="zipCode">Alternate Job Role</Form.Label>
                    <Col sm="6">
                        <Form.Select
                            name="alternateMemberType"
                            type="alternateMemberType" 
                            onChange={event => {
                                handleAlternateMemberType(event);
                                formik.handleChange(event);
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.alternateMemberType}
                        >
                            <option value=""></option>
                            {memberRoles.map((r, index) => (
                                <option 
                                    key={`alternate-member-type-${index}`} 
                                    value={r}
                                >
                                    {r}
                                </option>
                            ))}
                        </Form.Select>
                        {formik.touched.alternateMemberType && formik.errors.alternateMemberType ? (
                            <div style={{color:'red'}}>{formik.errors.alternateMemberType}</div>
                        ) : null}
                    </Col>
                </Form.Group>
                {hasAlternateSubspecialty && (
                    <Form.Group as={Row} className="mb-3">
                        <Form.Label column sm={4}htmlFor="alternateSubspecialty">Alternate Subspecialty</Form.Label>
                        <Col sm="6">
                            <Form.Select
                                id="alternateSubspecialty"
                                name="alternateSubspecialty"
                                type="text" 
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.alternateSubspecialty}
                            >
                                <option value=""></option>
                                {subspecialties.map((s, index) => (
                                    <option 
                                        key={`alternate-subspecialty-${index}`} 
                                        value={s.name}
                                    >
                                        {s.name}
                                    </option>
                                ))}
                            </Form.Select>
                            {formik.touched.alternateSubspecialty && formik.errors.alternateSubspecialty ? (
                            <div style={{color:'red'}}>{formik.errors.alternateSubspecialty}</div>
                        ) : null}
                        </Col>
                    </Form.Group>
                )} 
                <Row>
                    <Col sm={4} style={{margin:'0 auto'}}>
                        <Button type="submit" style={{width:'100%'}}>Submit {props.member ? 'Changes' : 'New Member'}</Button>
                    </Col>
                </Row>
            </Form>
            )}
            </div>
    )
}

export default MemberForm;