import Alert from 'react-bootstrap/Alert';
import parse from 'html-react-parser';

const Error = (props) => {
    return (
        <Alert variant="warning">
            {parse(props.message)}
        </Alert>
    );
};

export default Error;