import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../UI/Loading';
import Card from 'react-bootstrap/Card';
import UpdateProgramContact from '../Modal/UpdateProgramContact';
import { getOtherSurveyMember, updateIndividualAccount } from '../../lib/api';

const OtherSurveyMember = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [otherSurveyMember, setOtherSurveyMember] = useState();
    const programAccountId = useSelector(state => state.client.clientAccountId);

    useEffect(() => {
        if (!programAccountId) {
            return
        }
        const fetchProgramInfo = async () => {
            await getOtherSurveyMember(programAccountId).then((memberData) => {
                setOtherSurveyMember(memberData);    
                setIsLoading(false);           
            });
            
        }
        fetchProgramInfo();
    }, [programAccountId]);

    const updateRecord = async (data) => {
        setIsLoading(true);

        if (!data) {
            setIsLoading(false);
            return false;
        }

        if (otherSurveyMember) {
            const deleteRecord = {
                individualAccount: {
                    accountCustomFields: [
                        {
                            "id": 103,
                            "value":null
                        }
                    ]
                }
            }
    
            await updateIndividualAccount({accountId: otherSurveyMember['Account ID'], body: deleteRecord});
        }
        

        const addRecord = {
            individualAccount: {
                accountCustomFields: [
                    {
                        id: 103,
                        optionValues:[{
                            id:355
                        }]
                    }
                ]
            }
        }

        await updateIndividualAccount({accountId: data, body: addRecord});

        await getOtherSurveyMember(programAccountId).then((memberData) => {
            setOtherSurveyMember(memberData);    
            setIsLoading(false);           
        });
    }

    return (
        <Fragment>
            <Card>
                <Card.Body>
                    <Card.Title style={{borderBottom: '1px solid #c7ddec'}}>
                        Other Survey Member
                    </Card.Title>
                    <Card.Text style={{minHeight: '4rem'}}>
                    {!isLoading ? !otherSurveyMember ? (
                        <p>No Member Selected</p>) : (
                        <Fragment>
                            <span style={{fontSize: '1.25rem', lineHeight: '1.2', marginBottom: '0.5rem', fontWeight:'500'}}>{`${otherSurveyMember['First Name']} ${otherSurveyMember['Last Name']}`}{otherSurveyMember['Suffix'].length && (`, ${otherSurveyMember['Suffix']}`)}</span><br />
                            {otherSurveyMember['Address Line 1']}<br />
                            {otherSurveyMember['Address Line 2'] && otherSurveyMember['Address Line 2']}
                            <br />{otherSurveyMember['City']}, {otherSurveyMember['State/Province']} {otherSurveyMember['Zip Code']}
                        </Fragment>
                    ) : <Loading />}
                    </Card.Text>
                            
                            <UpdateProgramContact 
                                contactType="Other Survey Member" 
                                updateRecord={updateRecord} />
                            
                        
                </Card.Body>
            </Card>
        </Fragment>
        
    )
}

export default OtherSurveyMember;