import Card from 'react-bootstrap/Card';

const NotFound = () => {
    return (
        <Card style={{margin: '0 auto', maxWidth:"60%"}}>
            <Card.Body>
                <Card.Title>
                    Page Not Found
                </Card.Title>
                <Card.Text>
                    The page you requested could not be found.
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default NotFound;