import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import EditMember from '../Modal/EditMember';
import Error from '../UI/Error';
import LoaderSvg from '../UI/LoaderSvg';
import { removeProgramMember } from '../../lib/api';
import { clientActions } from '../../store/client-slice';

const MemberCard = ({member, removeMember}) => {
    const dispatch = useDispatch(false);
    const programAccountId = useSelector(state => state.client.clientAccountId);
    const [error, setError] = useState();
    const [firstName, setFirstName ] = useState(member['First Name']);
    const [lastName, setLastName ] = useState(member['Last Name']);
    const [suffix, setSuffix ] = useState(member['Suffix']);
    const [jobTitle, setJobTitle ] = useState(member['Job Title']);
    const [title, setTitle ] = useState(member['Title']);
    const [subSpecialty, setSubSpecialty ] = useState(member['Sub-Specialty']);
    const [addressLine1, setAddressLine1 ] = useState(member['Address Line 1']);
    const [addressLine2, setAddressLine2 ] = useState(member['Address Line 2']);
    const [city, setCity ] = useState(member['City']);
    const [stateProvince, setStateProvince ] = useState(member['State/Province']);
    const [zipCode, setZipCode ] = useState(member['Zip Code']);
    const [phone, setPhone] = useState(member['Phone 1 Full Number (F)']);
    const [email, setEmail] = useState(member['Email 1']);
    const [alternateMemberType, setAlternateMemberType] = useState(member['Alternate Member Type']);
    const [otherJobSpecialty, setOtherJobSpecialty] = useState(member['Other Job Specialty']);
    const [isPrimaryContact, setIsPrimaryContact] = useState(member.isPrimaryContact);
    const [deletePending, setDeletePending] = useState();

    const deleteMember = async () => {
        setDeletePending(true);
        setError(false);
        const removeResponse = await removeProgramMember({companyAccountId: programAccountId, firstName: member['First Name'], lastName: member['Last Name']});
        if (!removeResponse) {
            setDeletePending(false);
            return setError('Member could not be deleted');
        }

        removeMember(member['Account ID']);
        dispatch(clientActions.setShoppingCartStale(true));
        return setDeletePending(false);
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>{`${firstName} ${lastName}`}</Card.Title>
                    {isPrimaryContact === 'Yes' && (
                        <Row>
                            <Col><em>Primary Contact</em></Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={3}>Account ID</Col>
                        <Col xs={9}>{member['Account ID']}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}>Degree</Col>
                        <Col xs={9}>{suffix}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}>Job Role</Col>
                        <Col xs={9}>{jobTitle}</Col>
                    </Row>
                    {subSpecialty && (
                        <Row>
                            <Col xs={3}>Sub-Specialty</Col>
                            <Col xs={9}>{subSpecialty}</Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={3}>Title</Col>
                        <Col xs={9}>{title}</Col>
                    </Row>
                    {alternateMemberType && (
                        <Row>
                            <Col xs={3}>Alternate Job Role</Col>
                            <Col xs={9}>{alternateMemberType}</Col>
                        </Row>
                    )}
                    {otherJobSpecialty && (
                        <Row>
                            <Col xs={3}>Alternate Specialty</Col>
                            <Col xs={9}>{otherJobSpecialty}</Col>
                        </Row>
                    )}
                    <Row>
                        <Col xs={3}>Address</Col>
                        <Col xs={9}>
                            {addressLine1}
                            {addressLine2 && <div>{addressLine2}</div>}
                            <div>
                                {city}, {stateProvince} {zipCode}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={3}>Phone</Col>
                        <Col xs={9}>{phone}</Col>
                    </Row>
                    <Row>
                        <Col xs={3}>Email</Col>
                        <Col xs={9}>{email}</Col>
                    </Row>
                    <Row>
                        <Col xs={3} className="mt-3">
                            <EditMember 
                                accountId={member['Account ID']}
                                member={member}
                                firstName={setFirstName}
                                lastName={setLastName}
                                suffix={setSuffix}
                                jobTitle={setJobTitle}
                                title={setTitle}
                                subSpecialty={setSubSpecialty}
                                addressLine1={setAddressLine1}
                                addressLine2={setAddressLine2}
                                city={setCity}
                                stateProvince={setStateProvince}
                                zipCode={setZipCode}
                                phone={setPhone}
                                email={setEmail}
                                alternateMemberType={setAlternateMemberType}
                                otherJobSpecialty={setOtherJobSpecialty}
                                isPrimaryContact={setIsPrimaryContact}
                            />
                        </Col>
                        <Col xs={3} className="mt-3">
                            {deletePending ? <LoaderSvg /> : 
                            <Button variant="primary" 
                                size="sm"
                                onClick={deleteMember}
                            >Delete</Button>}
                        </Col>
                    </Row>
                    {error && (
                        <Row>
                            <Col xs={9} className="mt-3">
                                <Error message={error} />
                            </Col>
                        </Row>
                    )}
                    
                
            </Card.Body>
        </Card>
    )
}

export default MemberCard;