import { configureStore } from '@reduxjs/toolkit';
import clientReducer from './client-slice';
import userReducer from './user-slice';
import subspecialtiesReducer from './subspecialties-slice';
import membershipTermsReducer from './membership-terms-slice';
import membershipCustomFieldsReducer from './membership-custom-fields-slice';
import stateProvincesReducer from './state-provinces-slice';


const store = configureStore({
  reducer: { 
    client: clientReducer,
    user: userReducer,
    subspecialties: subspecialtiesReducer,
    membershipTerms: membershipTermsReducer,
    membershipCustomFields: membershipCustomFieldsReducer,
    stateProvinces: stateProvincesReducer 
  }
});

export default store;