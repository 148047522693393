import { useEffect, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Loading from '../UI/Loading';
import { userActions } from '../../store/user-slice';

const OfflineCheck = (props) => {
    const dispatch = useDispatch(false);
    const history = useNavigate();
    const cart = useSelector(state => state.client.shoppingCart);
    const [subTotal, setsubTotal] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setsubTotal(cart.subTotal);
    },[cart.subTotal]);

    const resetForm = () => {
        history('/payment', {replace: true});
    }

    const submitPayment = async () => {
        setLoading(true);

        dispatch(userActions.setTransactionComplete(true));
        history('/complete', {replace: true});
    }

    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    
    return (
        <Fragment>
            <Row>
                <Col sm={7} style={{margin:"0 auto"}}>
                    <h3>Pay with Offline Check</h3>
                    <h4>Total Amount Due: {USDollar.format(cart.totalCharge)}</h4>
                </Col>
            </Row>
            <Row style={{margin:"0 auto"}}>
                <Col sm={6} style={{margin:"0 auto"}}>
                    <p>Please remit your ${subTotal} payment to the following address:</p>
                    <p>APPD National Office<br />6728 Old McLean Village Dr.<br />McLean, VA 22101</p>
                </Col>
            </Row>
            {!loading ? (
                <Fragment>
                    <Row>
                        <Col sm={6} style={{margin:"0 auto"}}>
                        <Button onClick={() => submitPayment()}>Confirm Pay with Offline Check</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} style={{margin:"2.5rem auto"}}>
                            <Button className={'btn-secondary'} onClick={() => resetForm()}>Choose Another Payment Method</Button> 
                        </Col>
                    </Row>
                </Fragment>
            ) : <Loading />}
            
        </Fragment>
        
        
    )
}

export default OfflineCheck;