// TODO: Update view when adding new member
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Loading from '../UI/Loading';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import AddMember from '../Modal/AddMember';
import MemberCard from './MemberCard';
import Error from '../UI/Error';
import { getProgramMembers } from '../../lib/api';

const ListMembers = () => {
    const programAccountId = useSelector(state => state.client.clientAccountId);
    const memberRoles = useSelector(state => state.client.memberRoles);
    const [members, setMembers] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (!programAccountId) {
            return;
        }

        const getMembers = async () => {
            setError(false);
            await getProgramMembers(programAccountId, memberRoles).then((memberData) => {
                if (!memberData) {
                    setLoading(false);
                    setError('Could not load members');
                    return false;
                }
                setMembers(memberData);
                setLoading(false);
            });
        }

        getMembers();
    },[programAccountId, memberRoles]);

    const memberCount = (role) => {
        if (!members) return;
        
        return members.filter(member => member['Job Title'].toLowerCase() === role.toLowerCase()).length;
    }

    const removeMember = (member) => {
        const newMembers = members.filter(m => m['Account ID'] !== member);

        setMembers(newMembers);
    }

    const noRecords = (role) => {
        return (
            <Row>
                <Col sm={7} style={{margin:"1rem auto"}}>
                    <Alert>
                        No {role} Members
                    </Alert>
                </Col>
            </Row>
        )
    }

    return (
        <Fragment>
            <Row>
                <Col sm={9} style={{margin:"0 auto"}}>
                    <h2>{members && members.length} Members</h2>
                    <Alert variant={'danger'}>
                    DO NOT add a new person into an existing record! You must Delete anyone no longer at your program and ADD any new members under their corresponding Job Role/Member type.
                    </Alert>
                    <Alert variant={'danger'}>
                    If there are two members who are currently listed that have swapped Job Roles, just go to their records and Edit - change their Job Role and correct their titles. E.g. Listed APD is now PD and listed PD is now APD. Do not delete just edit each record to reflect their new Job Role.
                    </Alert>
                    <hr />
                </Col>
            </Row>
            {error ? (<Row>
                        <Col xs={9} className="mt-3">
                            <Error message={error} />
                        </Col>
                    </Row>) : loading ? <Loading /> : (
                memberRoles.map((role, idx) => {
                    return (
                        <div key={`role-${idx}`}>
                            <Row>
                                <Col md={9} style={{margin:"1rem auto"}}>
                                <h3>{role}</h3>
                                </Col>
                            </Row>
                            {!memberCount(role) && noRecords(role)}
                            {members.map((member, idx) => { 
                                if (member['Job Title'].toLowerCase() === role.toLowerCase()) {
                                    
                                    return (
                                        <Row key={`member-${idx}`}>
                                            <Col md={7} style={{margin:"1rem auto"}}>
                                                <MemberCard 
                                                    key={member['Account ID']} 
                                                    member={member} 
                                                    removeMember={removeMember}
                                                    membershipType={role}
                                                />
                                            </Col>
                                        </Row>
                                        
                                    )
                                }
                                else return null;
                            }
                            )}
                            <Row>
                                <Col sm={6} style={{margin:"1rem auto"}}>
                                    <AddMember
                                        members={members}
                                        setMembers={setMembers}
                                        membershipType={role} 
                                    />
                                </Col>
                            </Row>
                        </div>
                    )
                })
            )}
        </Fragment>
    )
}

export default ListMembers;