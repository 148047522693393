import Card from 'react-bootstrap/Card';

const AlreadyPaid = () => {
    return (
        <Card style={{margin: '0 auto', maxWidth:"60%"}}>
            <Card.Body>
                <Card.Title>
                    Dues Received
                </Card.Title>
                <Card.Text>
                    Your APPD Dues have already been submitted or invoiced. Thank you.
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default AlreadyPaid;