import { Fragment } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';

const Home = (props) => {
    const transactionComplete = useSelector(state => state.user.transactionComplete);
    const startDate = new Date(process.env.REACT_APP_TERM_START_DATE);
    const endDate = new Date(process.env.REACT_APP_TERM_END_DATE);
    
    const Instructions = () => {
        if (!transactionComplete) {
            return (
                <div>
                    <h3>Information and Instructions</h3>
                    <Alert variant="danger">**PLEASE NOTE: If you select Paying by Check, the program's membership records will only be updated to current for {startDate.getFullYear()}-{endDate.getFullYear()} once payment is received and applied at the APPD National Office.**</Alert>
                    <p>The APPD membership dues year is July 1-June 30.</p>
                    <h4 style={{textAlign:'center',margin:'2rem auto'}}>Dues Structures & Tiers</h4>
                    <p><strong>Basic dues for all tiers covers the following 8 core personnel:</strong> Pediatric Residency Program Director, the Department Chair, the Designated Institute Official, the Vice Chair of Education, one Associate Residency Program Director, one Fellowship Program Director, one Pediatric Residency Program Coordinator, and one Pediatric Fellowship Program Coordinator. <em>All Chief Residents and *PGYs 1-4 may be added without affecting the program's dues. When adding or renewing any of these please make sure you DO NOT use a group email address e.g. chiefres@..... Each person should have their own individual email address in order to be able to create their member login credentials.</em></p>
                    <p><strong><em>* New - Job Role is the "member type" that an individual will be listed under in our database. Please pay attention when completing your renewal as we have renamed/added some new Job Roles (member types).</em></strong></p>
                    <p><strong>TIER 1</strong><br />
                    $2,960 per accredited pediatric program and includes the 8 core personnel from your program (as noted above).
                    You may also add up to 10 more covered members at ${process.env.REACT_APP_SUBMEMBER_FEE} each in this tier.</p>
                    <p><strong>TIER 2</strong><br />
                    $7,620 Dues Structure: Basic+ includes Core Personnel (8) plus 11-20 additional individuals</p>
                    <p><strong>TIER 3</strong><br />
                    $9,030 Dues Structure: Basic + includes Core Personnel (8) plus 21-40 individuals</p>
                    <p><strong>TIER 4</strong><br />
                    $10,470: Basic+ includes Core Personnel (8) plus 41-52 individuals</p>
                    <p><strong>TIER 5</strong><br />
                    $11,245 Unlimited Dues Structure: Basic+ includes Core Personnel (8) plus 53 or more individuals.</p>
                    <p><strong>Programs are strongly encouraged to add all key educational faculty/personnel.</strong> This includes, but is not limited to: all fellowship program directors and coordinators, all associate program directors, global health educators, QI specialists, vice chairs of education, clinical educators, etc.</p>
                    <h4 style={{textAlign:'center',margin:'2rem auto'}}>Instructions</h4>
                    <p>Please review your program's membership information (make edits, add individuals, etc.). Your total amount will be automatically calculated on the Review and Payment page and will include your regional dues, when applicable. <strong>(Please pay this total to avoid being invoiced separately for your regional dues.)</strong> **If mailing a check, please click on the "Offline Check/Pay Later" link on the payment page, print the form, and return it with payment to the APPD office. Please Note: You will not be able to make changes to your membership once dues submission is complete. Please make all membership updates before submitting your membership renewal. All covered member addresses need to be their work address. Please do not submit home addresses.</p>
                    <p><strong>Listing More than One Job Role Per Individual:</strong><br />
                    Much of the communication and educational efforts through APPD are based on the different leadership roles within residency and fellowship programs. If an individual in your program represents more than one job role (e.g., an Associate Program Director may also be a Global Health Educator), please follow these instructions:</p>
                    <ul>
                        <li>Click the Members link in the top navigation menu</li>
                        <li>Scroll to find the individual that needs to be updated and click the Edit button inside their information card</li>
                        <li>In the Other Role section, select the individual's altername member type and alternate subspecialty (if applicable)</li>
                        <li>Click Save changes to save and close the update member's form</li>
                    </ul>
                    <p>Please DO NOT add anything here unless it is a different "Job Role" than what they are already listed as! (If the member is listed as Associate Program Director - do not use this to select that again. Only use if the member has 2 job roles example: Associate Program Director and Vice Chair of Education or any other role)</p>
                    <p><strong>If a currently listed member has changed Job Role</strong><br />click on edit in their information card to change the Job Role (member type). Please do not Delete and re-add them.</p>
                </div>
            )
        }
        else {
            return (<Alert>You have already submitted a membership renewal for the current membership term</Alert>)
        }
    }

    return (
        <Fragment>
            <Row>
                <Col md={9} style={{margin:"0 auto"}} className={'text-center'}>
                    <h2>APPD Membership Renewal</h2>
                </Col>
            </Row>
            <Row>
                {props.isAuthenticated ? (
                    <Col md={9} style={{margin:"0 auto"}}>
                        <Instructions />
                    </Col>
                ) : (
                    <Col md={9} style={{margin:"0 auto"}} className={'text-center'}>
                        <LinkContainer to="/login">
                            <Button>Log In</Button>
                        </LinkContainer>
                    </Col>   
                )}
            </Row>
        </Fragment>
    )
};

export default Home;