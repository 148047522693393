export function sanitizeIndividualAccount (accountData) {

    if (!accountData) {
        return false;
    }

    let response = {};

    accountData.accountCustomFields.map((customField) => {
        let value;

        if (customField.optionValues) {
            value = [];
            customField.optionValues.map((option) => {
                return value.push(option.name);
            });
        }
        else {
            value = customField.value;
        }
        return response[customField.name] = value;
    });

    response.accountId = accountData.accountId;
    response.firstName = accountData.primaryContact.firstName;
    response.lastName = accountData.primaryContact.lastName;
    response.degree = accountData.primaryContact.suffix;
    response.email = accountData.primaryContact.email1;
    response.jobTitle = accountData.primaryContact.title;
    response.salutation = accountData.primaryContact.salutation;
    response.addressId =  accountData.primaryContact.addresses[0] ? accountData.primaryContact.addresses[0].addressId : null;
    response.addressLine1 = accountData.primaryContact.addresses[0] ? accountData.primaryContact.addresses[0].addressLine1 : null;
    response.addressLine2 = accountData.primaryContact.addresses[0] ? accountData.primaryContact.addresses[0].addressLine2 : null;
    response.city = accountData.primaryContact.addresses[0] ? accountData.primaryContact.addresses[0].city : null;
    response.stateProvince = accountData.primaryContact.addresses[0] ? accountData.primaryContact.addresses[0].stateProvince : null;
    response.zipCode = accountData.primaryContact.addresses[0] ? accountData.primaryContact.addresses[0].zipCode : null;
    response.phone = accountData.primaryContact.addresses[0] ? accountData.primaryContact.addresses[0].phone1 : null;
    response.url = accountData.url;

    return response;
}
