import { Fragment } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const PaymentOptions = (props) => {

    return (
        <Fragment>
            <Row>
                <Col md={7} style={{margin: '2rem auto'}}>
                    <h3>Select a Payment Method</h3>
                </Col>
            </Row>
            <Row>
                <Col md={9} style={{margin: '2rem auto'}}>
                    <Card>
                        <Card.Body>
                            <Card.Title>
                                Selecting E-Check or Credit Card will navigate you to a secure online payment form
                            </Card.Title>
                            <Row>
                                <Col md={4} style={{margin: '2rem auto'}}>
                                    <Button style={{width: "90%"}} onClick={() => props.paymentMethod(12)}>E-Check/ACH</Button>
                                </Col>
                                <Col m={4} style={{margin: '2rem auto'}}>
                                    <Button style={{width: "90%"}} onClick={() => props.paymentMethod(3)}>Offline Check/Pay Later</Button>
                                </Col>
                                <Col md={4} style={{margin: '2rem auto'}}>
                                    <Button style={{width: "90%"}} onClick={() => props.paymentMethod(4)}>Credit Card</Button>
                                </Col>
                            </Row>
                            
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Fragment>
    )
}

export default PaymentOptions;