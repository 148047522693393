import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setJWT } from '../../lib/api';
import Error from '../UI/Error';


const Login = () => {
  const [error, setError] = useState();

  let navigate = useNavigate();

  useEffect(() => {
    let isSubscribed = true;

    const signIn = async () => {
      try {
        const params = {
          apiKey: process.env.REACT_APP_NEON_API_KEY,
          neonClientName: process.env.REACT_APP_NEON_API_USER,
          neonOauthId: process.env.REACT_APP_NEON_OAUTH_CLIENT_ID,
          neonOauthSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET
        };
        
        const response = await setJWT(params);

        if (response?.error) {
          setError(response.message);
          return false;
        }

        const { token } = response;
        document.cookie = `auth_code=${token};max-age=604800;domain=${process.env.REACT_APP_DOMAIN}`;
        navigate('/authenticate');
      }
      catch (ex) {
        setError(ex);
      }
      
    }

   if ( isSubscribed ) {
    signIn();
   }

    return () => isSubscribed = false;
  },[navigate]);


  return (
    <div>
      {error ? 
        <Error message={error} /> : 
        <p>Redirecting to login...</p>
      }
    </div>
  );
};

export default Login;