import { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import MemberForm from '../Members/MemberForm';
import { getIndividualAccount, updateIndividualAccount, getCustomFields, getProgramInformation } from '../../lib/api';
import Loading from '../UI/Loading';
import { sanitizeIndividualAccount } from '../../lib/sanitize-individual-account';
import { clientActions } from '../../store/client-slice';

const EditMember = (props) => {
    const dispatch = useDispatch(false);
    const history = useNavigate();
    const [formLoading, setFormLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [error, setError] = useState(false);
    const [member, setMember] = useState(props.member);
    const region = useSelector(state => state.user.region);
    const companyAccountId = useSelector(state => state.client.clientAccountId);

    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const updateRecord = async (formData) => {
        setFormLoading(true);
        setError(false);

        await getCustomFields().then(async (customFields) => {
            try {
                const data = {
                    individualAccount: {
                        primaryContact: {
                            firstName: formData.firstName,
                            lastName: formData.lastName,
                            salutation: formData.salutation,
                            suffix: formData.degree,
                            title: formData.jobTitle,
                            addresses: [{
                                ...member.addressId ? {addressId: member.addressId} : {isPrimaryAddress: true},
                                addressLine1: formData.addressLine1,
                                addressLine2: formData.addressLine2,
                                city: formData.city,
                                zipCode: formData.zipCode,
                                stateProvince: {
                                    code: formData.stateProvince
                                },
                                phone1: formData.phone
                            }],
                            email1: formData.email,
                        },
                        accountCustomFields: [
                            {
                                name: 'Alternate Member Type',
                                id: customFields.find(field => field.name === 'Alternate Member Type').id,
                                optionValues:  formData.alternateMemberType ? [{id: customFields.find(field => field.name === 'Alternate Member Type').optionValues.find(option => option.name === formData.alternateMemberType).id}] : null
                            },
                            {
                                name: 'Sub-Specialty',
                                id: customFields.find(field => field.name === 'Sub-Specialty').id,
                                optionValues:  formData.subSpecialty ? [{ id:customFields.find(field => field.name === 'Sub-Specialty').optionValues.find(option => option.name === formData.subSpecialty).id}] : null
                            },
                            {
                                name: 'Other Job Specialty',
                                id: customFields.find(field => field.name === 'Other Job Specialty').id,
                                optionValues:  formData.alternateSubspecialty ? [{ id:customFields.find(field => field.name === 'Other Job Specialty').optionValues.find(option => option.name === formData.alternateSubspecialty).id}] : null
                            },
                            {
                                name: 'Region',
                                id: customFields.find(field => field.name === 'Region').id,
                                optionValues: [{ id:customFields.find(field => field.name === 'Region').optionValues.find(option => option.name === region).id }] 
                            },
                            {
                                name: 'Title',
                                id: customFields.find(field => field.name === 'Title').id,
                                value: formData.title 
                            }
                        ]
                    }
                }

                const response = await updateIndividualAccount({accountId:props.accountId, body:data, companyAccountId: companyAccountId, isPrimaryContact: formData.isPrimaryContact});

                if (!response.error) {
                    dispatch(clientActions.setShoppingCartStale(true));
                    const memberData = await getIndividualAccount(props.accountId);
                    const sanitizedMemberData = sanitizeIndividualAccount(memberData);
                    setMember(sanitizedMemberData);
                    props.firstName(formData.firstName);
                    props.lastName(formData.lastName);
                    props.suffix(formData.degree);
                    props.jobTitle(formData.jobTitle);
                    props.title(formData.title);
                    props.addressLine1(formData.addressLine1);
                    props.addressLine2(formData.addressLine2);
                    props.city(formData.city);
                    props.stateProvince(formData.stateProvince);
                    props.zipCode(formData.zipCode);
                    props.phone(formData.phone);
                    props.email(formData.email);
                    props.alternateMemberType(formData.alternateMemberType);
                    props.otherJobSpecialty(formData.otherJobSpecialty);
                    props.subSpecialty(formData.subSpecialty);
                    props.isPrimaryContact(formData.isPrimaryContact);
                    if (member.jobTitle !== formData.jobTitle) {
                        history(0);
                    }
                    handleClose();
                    setFormLoading(false);
                }
                else {
                    setFormLoading(false);
                    setError(response.message);
                }
            }
            catch (ex) {
                setFormLoading(false);
                setError(ex.message);
            }

        });
    }
    
    return (
        <Fragment>
            <Button 
                variant="primary" 
                size="sm"
                onClick={handleShow}
            >Edit</Button>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Update {props.member['First Name']} {props.member['Last Name']}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {error && <Alert variant={'danger'}>There was an error updating the record. Please try again. Reason: {error}</Alert>}
                    {formLoading ? <Loading /> : (
                    <MemberForm 
                        member={member} 
                        membershipType={member['Job Title']}
                        submitForm={updateRecord}
                    />
                    )}
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button    
                        variant="secondary"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    )
}

export default EditMember;